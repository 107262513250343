import React, { Component } from 'react';

class Network extends Component {


	constructor(props) {
		super(props);

		this.network = props.network;
		this.state = {
			active: true,
		}
	}


	toggle = () => {
		try {

			if(this.props.dataLoad.filtersManager.allNetworksSelected()) {

			} else {
				this.props.dataLoad.filtersManager.networkToggle(this.network.id, !this.state.active);
				this.setState({active: !this.state.active});
			}
			this.props.onToggle(this.props.network);

		} catch(e) {
			alert("Error: "+e.message);
			//this.props.refresh();
		}
	}

	activate = () => {
		this.setState({active: true});
	}


	disable = () => {
		this.props.dataLoad.filtersManager.networkToggle(this.network.id, !this.state.active);
		this.setState({active: false});
	}

	renderCounter =  () => {
		if(this.props.network.newFeedItemCounter > 0) {
			return (<div className="count badge badge-danger">{this.props.network.newFeedItemCounter}</div>)
		}
		return null;
	}


	render() {

        var networkClass = "network";
        var networkImage =  this.network.fontAwesome;
       	if(this.state.active) {
			networkClass += " active";
		}

		return (
			<span className={networkClass} onClick={this.toggle}><i className={networkImage}></i>{this.renderCounter()}</span>
		)
	}
}



export default Network;