import React, {Component} from 'react';

class SummaryItem extends Component {


	render() {
		return (
			<div className="col-12 col-xs-6 col-md-4 col-lg-4 summary-item">
				<div className="summary-item-content">
					<div className="summary-item-value">{this.props.value}</div>
					<div className="summary-item-title">{this.props.title}</div>
				</div>
			</div>
		);
	}
}

export default SummaryItem;