
class BreadcrumbManager {

  constructor(props) {
  
    this.filtersManager = props.filtersManager;

  }


  getBreadcrumbText(since="", leaderboard="", timeline="") {

      var channelText = "";
      this.filtersManager.channelFilters.forEach((channelId, index) => {
            let channel = this.filtersManager.getChannel(channelId);
            if(channel !== null) {
                channelText += channel.name+" ";
                if(index < this.filtersManager.channelFilters.length - 1) {
                  channelText += "+ ";
                }
            }
      });

      var networkText = "";
      if(this.filtersManager.networkFilters.length > 0 && this.filtersManager.networkFilters.length < this.filtersManager.networksData.length) {
        this.filtersManager.networkFilters.forEach((networkId, index) => {
            let network = this.filtersManager.getNetwork(networkId);
            if(network !== null) {
                networkText += network.name+" | ";
            }
        });
      }


      var sinceText = since;
      if(sinceText.length > 0) {
        sinceText += " | ";
      }


      var timelineText = leaderboard + " " + timeline;

      return channelText + " | " + networkText + sinceText + timelineText;
  }



}

export default BreadcrumbManager;