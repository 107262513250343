import React, { Component } from 'react';
//import { Button } from 'reactstrap';
import Avatar from './avatar.js';

import './user-infos.css';

class UserInfos extends Component {


	userClicked = () => {
		this.props.userClicked(this.props.data);
	}

	render() {
		return (
			<div className="user-infos" onClick={this.userClicked}>
				<div className="wrapper">
					<div className="content">
						<Avatar src={this.props.data.pictureUrl}/>
						<div className="points">{this.props.data.gamePoints}</div>
						<div className="username">{this.props.data.name}</div>
					</div>
					<div className="rank">#{this.props.data.gameRank}</div>
				</div>
			</div>
		);
	}

}

export default UserInfos;