import React, { Component } from 'react';


class Avatar extends Component {
	render() {
		return (
			<img alt="avatar" src={this.props.src} className="avatar rounded-circle" />
		);
	}

}

export default Avatar;