import React, {Component} from 'react';
import './stats-page.css';
import BadgeItem from './badge-item';

const STAT_LIST = [
	{localizationId: 272, fetch: (data) => { return data === null ? 0 : data.totalPoints; } },
	{localizationId: 273, fetch: (data, userData) => { return userData.gameRank;/*ranking*/ } },
	{localizationId: 274, fetch: (data) => { return data === null ? 0 : data.totalVisits; } },
	{localizationId: 275, fetch: (data, userData) => { return data.followerCount;/*reach*/ } },
	{localizationId: 276, fetch: (data) => { return data === null ? 0 : data.totalActions; } },
	{localizationId: 277, fetch: (data) => { return data === null ? 0 : data.totalClicks; } },
	{localizationId: 278, fetch: (data) => { return data === null ? 0 : data.totalLikes; } },
	{localizationId: 279, fetch: (data) => { return data === null ? 0 : data.totalRetweet; } },
	{localizationId: 280, fetch: (data) => { return data === null ? 0 : data.totalShares; } },
	{localizationId: 282, fetch: (data) => { return data === null ? 0 : data.totalSharewhatsapp; } },
	{localizationId: 283, fetch: (data) => { return data === null ? 0 : data.totalSharemessenger; } },
	{localizationId: 438, fetch: (data) => { return data === null ? 0 : data.totalSharefacebook; } },
	{localizationId: 439, fetch: (data) => { return data === null ? 0 : data.totalSharetwitter;/*contributions*/ } },
	{localizationId: 437, fetch: (data) => { return data === null ? 0 : data.totalSharelinkedin;/*contributions*/ } },
	{localizationId: 281, fetch: (data) => { return data === null ? 0 : data.totalShareemail; } },
	{localizationId: 286, fetch: (data) => { return data === null ? 0 : data.totalCampaign; } },
];

class StatsPage extends Component {


	constructor(props) {
		super(props);

		this.state = {
			stats: this.props.dataLoad.userStats
		}
	}

	componentDidMount() {
		//this.getUserStats();
	}


	getUserStats() {
		//this.props.dataLoad.getCurrentUserStats().then((res) => this.setState({stats: res}));
	}

	
	render() {
		return (
			<div className="stats-page page">
					<div className="content">
						<div className="title">{ this.props.dataLoad.languageManager.get(12) }</div>
						<div className="stats">
							<div className="rank"><BadgeItem footerText={"#"+this.props.dataLoad.userData.gameRank} onBadgeClicked={this.userBadgeClicked}>{this.renderUserContent()}</BadgeItem></div>
							<div className="latest-badge"><BadgeItem footerText={this.props.dataLoad.languageManager.get(256)} onBadgeClicked={this.achievementBadgeClicked}>{this.renderAchivementContent()}</BadgeItem></div>
							{this.renderRewardBlock()}

							</div>
						<div className="title">{ this.props.dataLoad.languageManager.get(257) }</div>
						<div className="overview">
							<div className="stats-container">
							{
								STAT_LIST.map((stat, index) => {
									let realIndex = index%4;
									var position = "first";


									if(realIndex === 1) {
										position = "second";
									} else if(realIndex === 2) {
										position = "third";
									} else if(realIndex === 3) {
										position = "fourth";
									}

									let value = stat.fetch(this.state.stats, this.props.dataLoad.userData);
									if(value == "N/A") {
										position = "disabled";
									}

									return (
										<div className={"stat "+position}><div className="ratio"><div className="ratio-content"><div className="value">{value}</div><div className="label">{this.props.dataLoad.languageManager.get(stat.localizationId) }</div></div></div></div>
									);
								})
							}
							</div>
						</div>
					</div>
			</div>
		);
	}

	renderRewardBlock = () => {
		if(this.rewardSelector(this.props.dataLoad.rewards) == null) {
			return null;
		}

		let footerText = null;
		if(this.rewardSelector(this.props.dataLoad.rewards).rewardPoints > 0) {
			footerText = this.rewardSelector(this.props.dataLoad.rewards).rewardPoints.toLocaleString()+" "+this.props.dataLoad.languageManager.get(79);
		}
 		return (<div className="reward"><BadgeItem footerText={footerText} onBadgeClicked={this.rewardBadgeClicked}>{this.renderRewardContent()}</BadgeItem></div>);			
	}



	renderUserProgress = () => {
		return null;
		let progress = 3;
		if(progress >= 0) {
			return (<div className="user-progress text-success"><i className="fas fa-caret-up"></i>{"+"+progress}</div>);
		} else {
			return (<div className="user-progress text-danger"><i className="fas fa-caret-down"></i>{progress}</div>);
		}
	}

	renderUserContent = () => {
		return (<div className="text-center"><img className="avatar" src={this.props.dataLoad.userData.pictureUrl}></img><div className="game-points">{this.props.dataLoad.userData.gamePoints}</div><div className="username">{this.props.dataLoad.userData.name}</div>{this.renderUserProgress()}</div>);

	}

	renderAchivementContent = () => {
		let latestBadge = this.props.dataLoad.getUserLatestBadge();

		if(latestBadge == undefined) {
			return null;
		}
		return (<div><img className="badge-image" src={process.env.REACT_APP_BASE_URL+latestBadge.pictureUrlActivated} ></img><div className="badge-name">{latestBadge.nameActivated}</div></div>);
	}

	renderRewardContent = () => {
		let lastReward = this.rewardSelector(this.props.dataLoad.rewards);
		if(!lastReward) {
			return null;
		}
		return (<div><img className="reward-image" src={process.env.REACT_APP_BASE_URL+lastReward.imageURL} ></img></div>);
	}



	rewardSelector = (rewards) => {
		let lastRewardIndex  = 0;
		let points = this.props.dataLoad.userData.gamePoints;

		let unlockedRewards = rewards.filter((reward) => {
			return reward.rewardPoints <= points;
		});

		let foundRewardIndex  = unlockedRewards.length - 1;
		if(foundRewardIndex > lastRewardIndex) {
			lastRewardIndex = foundRewardIndex;
		}
		if(lastRewardIndex > (rewards.Length -1)) {
			return null;
		}
		return rewards[lastRewardIndex];
	}



	userBadgeClicked = () => {

	}


	achievementBadgeClicked = () => {

	}

	rewardBadgeClicked = () => {

	}
}



export default StatsPage;