import React, { Component } from 'react';
import "./campaign.css";


import TextFormatter from '../../logic/text-formatter';

class Campaign extends Component {

	render  = () => {

		let descriptionHtml = {__html: this.props.campaign.description}



		return (<div className="campaign">
					<div className="visual">
						<img alt="campaign" src={process.env.REACT_APP_BASE_URL+this.props.campaign.typeLogoURL} />
						<div className="campaign-tag"><div className="campaign-bonus">{this.props.campaign.bonusName}</div></div>
					</div>
					<div className="content">
						<div className="name">
							{this.props.campaign.name}
						</div>
						<div className="description" dangerouslySetInnerHTML={descriptionHtml}>
						</div>
						<div className="date">
						 {this.props.dataLoad.languageManager.get(99)} {TextFormatter.formatCampaignDate(this.props.campaign.dateStart)} {this.props.dataLoad.languageManager.get(100)} {TextFormatter.formatCampaignDate(this.props.campaign.dateEnd)}
						</div>
					</div>
				</div>)
	}

}

export default Campaign;