import React, { Component } from 'react';
import "./viewport-display.css";


class ViewportDisplay extends Component {

	constructor(props) {
		super(props);

		this.state = {
			size: this.getViewportSize(),
		}
	}

	componentDidMount() {
		window.addEventListener('resize', this.refreshSize);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.refreshSize);
	}

	refreshSize = () => {
		this.setState({size: this.getViewportSize()});
	}

	getViewportSize = () => {
		let w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
		let h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

		return {width: w, height: h};
	}


	getViewportString = () => {
		return this.state.size.width+"X"+this.state.size.height;
	}



  render() {
  	return(
  		<div className="viewport-display">
  			<div className="content">
  				Viewport: {this.getViewportString()}
  			</div>
  		</div>
  	);
  }
}

export default ViewportDisplay;
