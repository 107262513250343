import React, { Component } from 'react';
import "./update-popup.css";



class UpdatePopup extends Component {

  render() {
    return (<div className="update-notification">Update available: <button onClick={this.props.onUpdateAccepted}>click here to install</button><div className="close" onClick={this.props.onUpdateRefused}>&times;</div></div>);
  }
}

export default UpdatePopup;
