import React, { Component } from 'react';
import "./edit-group-master-item.css";

import TextFormatter from '../../logic/text-formatter';


class EditGroupMasterItem extends Component {


	componentDidMount() {
		

	}


	renderDate = () => {
		console.log(this.props.item);
		let finalText = TextFormatter.formatDate(this.props.item, false, this.props.dataLoad.languageManager);

		return finalText;
	}


	render = () => {
		var itemClass = "group-item master";
		//let feedClass = "fa "+this.props.item.parentFeed.parentFeedType.fontAwesome;
		let imageSrc = process.env.REACT_APP_BASE_URL+this.props.item.imageURL;
      let item = this.props.item;
 const regex = /(<([^>]+)>)/ig;

      //let titleHtml = { __html: unescape(this.props.data.title)};
      var titleText = unescape(item.title).replace(regex, '');
      let titleHtml = { __html: unescape(titleText)};
      //let bodyText = unescape(item.body).replace(regex, '');




      let networkClass = item.parentFeed.parentFeedType.fontAwesome;
      /*var itemClasses = "child ";
      if(item.onlyImage === true) {
        itemClasses += " only-image";
      }
      if(this.props.edit == true) {
        itemClasses += " edit";
      }*/
      //var colClasses = "col-xs-12 col-sm-6 col-md-3";


     /* var image="";
      if(item.imageURL.length > 0) {
          let imageStyle = { backgroundImage: `url(https://stage.go4.social${item.imageURL})` }
          image = <div className="image" style={imageStyle}></div>
      }*/


      var likeCount = "";
      if(item.likeCounter > 0) {
        likeCount = <span className="count"><i className="fa fa-heart" />{TextFormatter.formatNumber(item.likeCounter)}</span>
      }

      var viewCount = "";
      if(item.viewCounter > 0) {
        viewCount = <span className="count"><i className="fa fa-eye" />{TextFormatter.formatNumber(item.viewCounter)}</span>
      }

      var shareCount = "";
      if(item.shareCounter > 0) {
        shareCount = <span className="count"><i className="fa fa-share-alt" />{TextFormatter.formatNumber(item.shareCounter)}</span>
      }


		return (
			<div className={itemClass}>

				<div className="group content-list row no-gutters">
					<div className="col col-2 image">
						<img alt="master" src={imageSrc} />
					</div>
					<div className="col col-10 infos">
						 <div className="body">
			                  <div className="header">{likeCount}{viewCount}{shareCount}<i className="fa fa-star" /></div>
			                  <div className="body-content">
			                    <div className="social-icon"><i className={networkClass} /></div>
			                    <h1 dangerouslySetInnerHTML={titleHtml}></h1>
			                  </div>
			                  <div className="footer"><div className="time">{this.renderDate()}</div></div>
			              </div>
					</div>

				</div>
				
			</div>
		);
	}

}



export default EditGroupMasterItem;