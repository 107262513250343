import React, { Component } from 'react';
import "./overlay.css";



class Overlay extends Component {




  stopPropagation = (event) => {
    event.stopPropagation();
  }



  getOverlayClassName = () => {
      let classes  = ["overlay"];
      if(this.props.content !== null) {
        classes.push("active");
      }
      if(this.props.clean !== undefined && this.props.clean === true) {
        classes.push("clean");
      }
      if(this.props.rounded !== undefined && this.props.rounded === true) {
        classes.push("rounded");
      }

      return classes.reduce((result, aClass) => { return result + ' ' + aClass}, this.props.className || '');
  }

  render() {

  	return(
  		<div className={this.getOverlayClassName()} onClick={this.props.exit}>
          <div className="content" onClick={this.stopPropagation}>
            <div className="close"><i onClick={this.props.exit} className="fa fa-times-thin"></i></div>
            <div className="content-wrapper">
             {this.props.content}
            </div>
          </div>
  		</div>
  	);
  }
}

export default Overlay;
