import moment from 'moment';

class TextFormatter {

  static formatNumber(number) {
      if(number > 1000000) {
          return (number / 1000000.0).toFixed(1) + 'M';
      } else if(number > 100000) {
          return Math.round(number / 1000.0) + 'k';
      } else if(number > 1000) {
          return (number / 1000.0).toFixed(1) + 'k';
      }

      return number;
  }


  static formatDate = (data, showTrueDate, languageManager, forceFeature=false) => {

    var finalText = "";

    if(showTrueDate) {
        finalText = moment(data.datePublished).format('L');
    } else {
      let pastMinutes= data.pastMinutes;

      var sinceTextFormat = languageManager.get(37);
      var timeText = "";
      var value = null;
      if(pastMinutes < 60) {
        if(pastMinutes > 1) {
          timeText = languageManager.get(43);
        } else {
          timeText = languageManager.get(44);
        }
        value = pastMinutes;

      } else if(pastMinutes < (60*24)) {

          value = Math.floor(pastMinutes / 60);
          if(value > 1 ) {
              timeText = languageManager.get(41);
          } else {
             timeText = languageManager.get(42);
          }
          
      } else {
          value = Math.floor(pastMinutes / (60*24));
          if(value > 1) {
            timeText = languageManager.get(39);
          } else {
            timeText = languageManager.get(40);
          }
      }


      if(data.isFeature || forceFeature) {
        finalText = languageManager.get(109);
      } else if(pastMinutes < 1) {
        finalText = languageManager.get(38);
      } else {
        finalText = sinceTextFormat.replace("%d", value+" "+timeText);
      }


    }
    return finalText;

  }


  static formatCampaignDate = (date) => {
     return moment(date).format('L');
  }


  static formatUserCreationDate = (user) => {
    return moment(user.dateCreated).format('L');
  }




  static decodeHTMLEntities = (str, stripTags=false) => {
    // this prevents any overhead from creating the object each time
    var element = document.createElement('div');

    // regular expression matching HTML entities
    var entity = /&(?:#x[a-f0-9]+|#[0-9]+|[a-z0-9]+);?/ig;

    if(stripTags === true) {
      str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
      str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
    }

        // find and replace all the html entities
    str = str.replace(entity, function(m) {
        element.innerHTML = m;
        return element.textContent;
    });

    // reset the value
    element.textContent = '';

    return str;
  }


}

export default TextFormatter;