import React, {Component} from 'react';
import MyActivityItem from './my-activity-item';


class MyActivity extends Component {

	render() {
		return (
			<div className="my-activity">
				{
					this.props.data.map((activityData, index) => {
						return (
							<MyActivityItem key={index} data={activityData} /> 
						);
					})
				}
			</div>
		)
	}

}


export default MyActivity;