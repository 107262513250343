import React, {Component} from 'react';
import UserInfos from './user-infos';


class Leaderboard extends Component {


	render() {
		return (
			<div className="leaderboard row">
			{
				this.props.data.map(userData => {
					return (
						<div key={userData.id} className="col">
							<UserInfos data={userData} />
						</div>
					)
				})
			}
			</div>
		);
	}


}


export default Leaderboard;

/*
function populateLeaderBoardBox() {

    let dateFrom = new Date(2016, 0, 1).getTime();
    let dateTo = Date.now();

    $.ajax({
        dataType: "json",
        url: '/ws/apireporting/leaderboard/?topx=20&datefrom=' + dateFrom + '&dateto=' + dateTo,
        success: function (json) {

            let html = '';

            html += '<h1>' + dico.get(122, language) + '</h1>';

            html += '<div class="row">';
            html += '  <div class="col-sm-12 horizontalscroll">';
            html += '    <div style="width: ' + ((250 * (json.length)) + 10) + 'px;">';

            for (var i = 0; i < json.length; i++) {
                html += getUserBox(json[i], '250px');
            }

            html += '    </div>';
            html += '  </div>';
            html += '</div> ';

            $('#divLeaderBoard').html(html);
        }
    });
}*/