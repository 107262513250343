import React, {Component} from 'react';
import './channels-page.css';


class ChannelsPage extends Component {

	channelClicked = (channel) => {
		window.location = "/app/channel/"+channel.slug;
	}


	render() {
		return (
			<div className="channels-page page">
					<div className="content">
					{
						this.props.dataLoad.channels.filter((channel) => { return channel.isTelex === false}).map((channel) => {
							return (<div className="channel" onClick={this.channelClicked.bind(this, channel)}>
										<div className="channel-content">
											<img src={process.env.REACT_APP_BASE_URL+channel.imageURL} />
											<div className="channel-name">{channel.name}</div>
										</div>
									</div>)
						})
					}
					</div>
			</div>
		);
	}
}



export default ChannelsPage;