import React, { Component } from 'react';
import "./edit-campaign-items-content.css";
import CampaignItem from './campaign-item';

import Campaign from './campaign';


import CampaignItemsManager from '../../logic/campaign-items-manager';

class EditCampaignItemsContent extends Component {

	constructor(props) {
		super(props);


		this.state = {
			items: [],
			selectedItems: this.props.selectedItems
		}

		this.itemsManager = new CampaignItemsManager({dataLoad: this.props.dataLoad, campaign: this.props.campaign});


	}

	componentDidMount() {
		this.props.dataLoad.getItemsForCampaign(this.props.campaign.id).then((items) => {

			//let finalItems = items.concat(this.state.selectedItems);

			var itemsToAdd = this.state.selectedItems.filter((item, index) => {
				return !items.find((fetchItem) => {
					return fetchItem.id === item.id;
				});
			});
			console.log("Selected :");
			console.log(this.state.selectedItems);
			console.log(itemsToAdd);
			this.setState({items: items.concat(itemsToAdd)});
		});
	}


	stopPropagation = (event) => {
		event.stopPropagation();
	}


	renderItems = () => {
		return this.state.items.map((item, index) => {
			return <CampaignItem key={index} data={item} defaultSelected={item.campaign.id === this.props.campaign.id} itemsManager={this.itemsManager}/>
		});
	}


	cancel = (event) => {
		this.props.cancel();
	}

	next = () => {
		this.itemsManager.validateItems().then(response => {
			this.props.refresh();
			this.props.cancel();
		});
	}


	render() {
		return (<div className="edit-campaign-items" onClick={this.stopPropagation}>
			<div className="header"><div className="title">{this.props.dataLoad.languageManager.get(51)}</div></div>
			<hr />
			<Campaign dataLoad={this.props.dataLoad} campaign={this.props.campaign} />
			<div className="body">

				{this.renderItems()}
				<div className="controls">
					<button className="btn btn-danger cancel" onClick={this.cancel}>{this.props.dataLoad.languageManager.get(93)}</button><button className="btn btn-success next" onClick={this.next}>{this.props.dataLoad.languageManager.get(92)}</button>
				</div>
			</div>
			</div>);
	}

}


export default EditCampaignItemsContent;