import React, {Component} from 'react';

import Network from './network';





class Networks extends Component {


	render() {
		return (
			<div className="networks">
			{
					this.props.data.filter(networkData => { return networkData.linkable }).map((networkData, index) => {
						let even = (index % 2 === 0);
						return (<Network key={networkData.feedTypeId} even={even} data={networkData} localize={this.props.localize} bearer={this.props.bearer} onReloadUser={this.props.onReloadUser} />);
					})
			}
			</div>
		);
	}
}


export default Networks;