import React, { Component } from 'react';
import "./edit-options.css";

class EditOptions extends Component {

	onClickCampaign = () => {
		this.props.onClickCampaign();
	}


	onClickHide = () => {
			this.props.onChangeItemsDisplay(true);
	}

	onClickShow = () => {
			this.props.onChangeItemsDisplay(false);
	}


	onClickGroup = () => {
		this.props.onClickGroup();
	}



	render() {
		return <div className="edit-options row no-gutters">
			<div className="label-col">
			<label>{this.props.dataLoad.languageManager.get(18)}</label>
			</div>
			<div className="options">
			<button className="btn btn-secondary" disabled={!this.props.buttonsEnabled} onClick={this.onClickHide}>{this.props.dataLoad.languageManager.get(53)} <i className="fa fa-eye-slash"></i></button>
			<button className="btn btn-secondary" disabled={!this.props.buttonsEnabled} onClick={this.onClickShow}>{this.props.dataLoad.languageManager.get(54)} <i className="fa fa-eye"></i></button>
			<button className="btn btn-secondary" disabled={!this.props.buttonsEnabled} onClick={this.onClickGroup}>{this.props.dataLoad.languageManager.get(48)} <i className="fas fa-link"></i></button>
			<button className="btn btn-secondary" onClick={this.props.onClickCampaign}>{this.props.dataLoad.languageManager.get(121)} <i className="fa fa-bullhorn"></i></button>
			</div>

			<div className="global-options"><button className="btn btn-secondary" onClick={this.props.reset}>{this.props.dataLoad.languageManager.get(98)} <i className="fa fa-times" /></button><button className="btn btn-secondary" onClick={this.props.onCloseEdit}>{this.props.dataLoad.languageManager.get(97)} <i className="fa fa-toggle-on" /></button></div>
			<div className="col-12"><hr /></div>
		</div>;
	}


}



export default EditOptions;