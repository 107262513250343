import React, { Component } from 'react';
import {Link} from "react-router-dom";
import "./page-error.css";


import Navbar from './navbar';



const customErrors = {
	"404": {
		"image": "/images/frown-regular.svg",
		"message": "Sorry we couldn't find the page you're looking for."
	},
	"500": {
		"image": "/images/sad-cry-regular.svg",
		"message": "An error occured, we're sorry for the inconvenience."
	}
}

class PageError extends Component {


	constructor(props) {
		super(props);
		this.prefix = process.env.PUBLIC_URL;
	}


  render() {

  	  let {image, message} = customErrors[this.props.error];
  	  let prefix = "/app";
  	return(
  		<div className="page-error container">
  			<Navbar url="/" title="Return home" forceLogout={this.props.forceLogoutOnreturn} />
  			<div className="content">
  				<div className="logo"><img src={this.prefix+image} /></div>
  		  		<h1>{message}</h1>
          	</div>
      </div>
  	);
  }
}

export default PageError;
