import React, { Component } from 'react';
import "./component-loader.css";



class ComponentLoader extends Component {


  stopPropagation = (event) => {
    event.stopPropagation();
  }


  render() {
    var loaderClass = "component-loader";
    if(this.props.transparent) {
      loaderClass += " transparent";
    }

  	return(
  		<div className={loaderClass} onClick={this.stopPropagation}>
             <img className="logo" src="/app/images/logo512x512.png" alt="Loading..."/>
  		</div>
  	);
  }
}

export default ComponentLoader;
