import React, { Component } from 'react';
import ComponentOverlay from '../common/component-overlay';
import ComponentLoader from '../common/component-loader';

import DisplaySelector from "./display-selector";

import './create-post.css';


const DEFAULT_TITLE_MAX_CHAR_COUNT = 160;

const formErrorRequired = "Champ requis";

class CreatePost extends Component {


	constructor(props) {
		super(props);

		this.filterId = this.props.filterId;
		this.fileInputRef = React.createRef();

		this.formErrors = [];


		this.titleMaxCharCount = DEFAULT_TITLE_MAX_CHAR_COUNT;


		this.state = {
			loaded: false,
			sending: false,
			imageFile: null,
			imageSrc: null,
			displayMode: null,
			feeds: [],
			selectedFeed: null,
			title: "",
			body: "",
			url: "",
			error: "",
			formErrors: {},
			titleCharCount: 0
		}
	}

//feeditem_image
//feeditem_title
//feeditem_body
//feeditem_feed
//feeditem_url

	componentDidMount() {
		this.props.dataLoad.getFeeds().then((feeds) => {
			const displayedFeeds = feeds.filter((feed) => {return feed.parentFeedType.id == 1});
			if(displayedFeeds.length > 0) {
				this.setState({loaded:true, feeds: displayedFeeds, selectedFeed: displayedFeeds[0].id});
			} else {
				this.setState({loaded:true, error:"Not feed to display"});
			}
		});
	}



	onTitleChange(event) {
		var formErrors = this.state.formErrors;
		if(Object.keys(formErrors).includes("title")) {
			 delete formErrors["title"];
		}
		var finalValue = event.target.value;
		if(finalValue.length > this.titleMaxCharCount) {
			finalValue = finalValue.slice(0,this.titleMaxCharCount);
		}
		event.target.value = finalValue;
		this.setState({title: finalValue, titleCharCount: finalValue.length, formErrors: formErrors});
	}

	onBodyChange(event) {
		var formErrors = this.state.formErrors;
		if(Object.keys(formErrors).includes("body")) {
			 delete formErrors["body"];
		}
		this.setState({body: event.target.value, formErrors: formErrors});
	}

	onDisplayModeChange(val) {
		this.setState({displayMode: val});
	}

	onUrlChange(event) {
		this.setState({url: event.target.value});
	}

	onFeedChange(event) {
		this.setState({selectedFeed: event.target.value});
	}


	validateForm(onValidated) {
		var formErrors = {};
		if(this.state.title.length == 0) {
			formErrors["title"] = formErrorRequired;
		}

		/*if(this.state.body.length == 0) {
			formErrors["body"] = formErrorRequired;
		}*/

		if(this.state.selectedFeed == null) {
			formErrors["feed"] = formErrorRequired;
		}

		this.setState({formErrors: formErrors}, (() => {
			if(Object.keys(this.state.formErrors).length == 0) {
				onValidated();
			}
		}).bind(this));
	}

	resetFormErrors() {
		this.setState({formErrors: {}});
	}


	createPost(event) {
		event.preventDefault();
		this.validateForm(this.doCreatePost.bind(this));
	}


	doCreatePost(event) {
		console.log("Creating post");
		this.setState({sending: true, error: ""});
		const formData = this.forgeFormData();

		//this.setState({sending: false, error:"This is a test error"});
		this.props.dataLoad.createPost(formData).then((response) => {
			if(response.success == false) {
				throw new Error(response.error);
			}
			this.setState({sending: false});
			this.props.exit(response.points, response.data, this.state.imageSrc);
		}).catch((error) => {
			const message = "An error occured : "+error.message;
			this.setState({sending: false, error: message});
		});
	}

	readFiles(event) {
		console.log("Read files");
		const file = this.fileInputRef.current.files[0];
		this.setImage(file);
	}

	triggerInput() {
		this.fileInputRef.current.click();
	}

	setImage(file) {
		if(FileReader && file) {
			var fr = new FileReader();
			fr.onload = () => {
				this.setState({imageSrc: fr.result, imageFile: file});
			}
			fr.readAsDataURL(file);
		}		
	}

	removeImage(event) {
		event.preventDefault();
		this.fileInputRef.current.value = null;
		this.setState({imageSrc: null, imageFile: null, displayMode: null});
	}

	forgeFormData() {
	  let fd = new FormData();


	  const finalUrl = 'https://'+this.state.url.replace('https://', '').replace('http://', '');

	  fd.append("feeditem_image", this.state.imageFile);
	  fd.append("feeditem_title", this.state.title);
	  fd.append("feeditem_body", this.state.body);
	  fd.append("feeditem_url", finalUrl);
	  fd.append("feeditem_feed", this.state.selectedFeed);
	  if(this.state.displayMode !== null) {
	  	 fd.append("displayMode", this.state.displayMode+1);
	  }
	  


	  return fd;
	}

	renderImageControl() {
		const placeholder = (<div onClick={this.triggerInput.bind(this)}><i className="fas fa-camera large"></i>
			<div className="btn">{this.props.dataLoad.languageManager.get(265)}</div></div>);
		var content = placeholder;
		var deleteButton = null;
		if(this.state.imageSrc !== null) {


			content = (<DisplaySelector displayType={0} imageSrc={this.state.imageSrc} title={this.state.title} body={this.state.body} dataLoad={this.props.dataLoad} onDisplayModeChange={this.onDisplayModeChange.bind(this)}></DisplaySelector>);
			deleteButton = (<button className="btn text-danger btn-remove" onClick={this.removeImage.bind(this)}>{this.props.dataLoad.languageManager.get(455)}</button>)
	
		}
		
		return (<div className="image-control">
					<div className="post-image">
						{content}
					</div>
					{deleteButton}
					<input ref={this.fileInputRef} type="file" className="hidden" onChange={(event)=> { console.log("Change"); this.readFiles(event)}}></input>
			</div>);
	}


	renderError() {
		if(this.state.error !== "") {
			return <div className="error text-danger">{this.state.error}</div>
		}
		return null;
	}


	renderOverlay() {
		if(this.state.sending)
			return (<ComponentLoader></ComponentLoader>);
		return null;
	}


	renderFeedSelect() {
		return (<div className="form-group">
					<label htmlFor="feed">{this.props.dataLoad.languageManager.get(478)}</label>
					<select className="form-control feed" name="feed" onChange={this.onFeedChange.bind(this)}>
						{this.state.feeds.map((feed, index) => {
							return (<option key={feed.id} value={feed.id}>{feed.name}</option>)
						})}
					</select>
				</div>);
	}


	renderTitleCounter() {
		var counterClass = "char-counter";
		if(this.state.titleCharCount == this.titleMaxCharCount) {
			counterClass += " text-danger";
		}
		if(this.state.titleCharCount == 0) {
			counterClass += " fade-out";
		} else {
			counterClass += " fade-in";
		}
		return <span className={counterClass}>{this.state.titleCharCount}/{this.titleMaxCharCount}</span>;
	}

	renderTitleGroup() {

		var titleClass = "form-control title";
		var titleError = null;
		if(Object.keys(this.state.formErrors).includes("title")) {
			titleClass += " is-invalid";
			titleError = (<div className="invalid-feedback">{this.state.formErrors.title}</div>);
		}

		return(<div className="form-group">
			<label htmlFor="title" className="counter-label"><span className="label-title">{this.props.dataLoad.languageManager.get(262)}</span>{this.renderTitleCounter()}</label>
			<textarea className={titleClass} type="textarea" name="title" onChange={this.onTitleChange.bind(this)} placeholder={this.props.dataLoad.languageManager.get(454)}></textarea>
			{titleError}
		</div>);		
	}


	renderBodyGroup() {


		var bodyClass = "form-control body";
		var bodyError = null;
		if(Object.keys(this.state.formErrors).includes("body")) {
			bodyClass += " is-invalid";
			bodyError = (<div className="invalid-feedback">{this.state.formErrors.body}</div>);
		}

		return (
			<div className="form-group">
				<label htmlFor="body">{this.props.dataLoad.languageManager.get(263)}</label>
				<textarea className={bodyClass} type="textarea" name="body" onChange={this.onBodyChange.bind(this)} placeholder={this.props.dataLoad.languageManager.get(261)}></textarea>
				{bodyError}
			</div>			
		);
	}

	renderUrlGroup() {
		return (<div className="form-group">
			<label htmlFor="url">{this.props.dataLoad.languageManager.get(304)}</label>
 			<div class="input-group mb-2">
	        	<div class="input-group-prepend">
	          		<div class="input-group-text">https://</div>
	        	</div>
				<input className="form-control" type="text" name="url" onChange={this.onUrlChange.bind(this)} placeholder={this.props.dataLoad.languageManager.get(254)} />
			</div>
		</div>);		
	}

	render() {
		if(this.loaded == false) {
			return <ComponentLoader></ComponentLoader>;
		}

		return (
			<div className="create-post">
				<h2>{this.props.dataLoad.languageManager.get(258)}</h2>
				<form className="form">
					{this.renderImageControl()}
					{this.renderTitleGroup()}
					{this.renderBodyGroup()}
					{/*this.renderUrlGroup()*/}
					<div className="form-group">
					{/*<label htmlFor="feed">{this.props.dataLoad.languageManager.get(0)}</label>*/}
					{this.renderFeedSelect()}
					</div>
					<div className="text-center">
					<input type="submit" className="btn btn-success" value={this.props.dataLoad.languageManager.get(46)} onClick={this.createPost.bind(this)} />
					{this.renderError()}
					</div>
				</form>
				{this.renderOverlay()}
			</div>
		)
	}

}



export default CreatePost;