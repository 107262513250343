
class FiltersManager {

  constructor(props) {


    this.channelsData = null;
    this.networksData = null;
    this.channelFilters = [];
    this.networkFilters = [];


    this.leaderboardFilter = '';
    this.timelineFilter = 0;
    this.sinceFilter = 0;

    this.searchFilter = '';

    this.globalSearch = false;

  }


  channelToggle = (channelId, add) => {
      if(add) {
          this.channelFilters = this.channelFilters.concat([channelId]);
      } else {
         this.channelFilters = this.channelFilters.filter((value, index, arr) => { 
              return value !== channelId;
         });

         if(this.channelFilters.length == 0) {
          console.log('NO channels selected reseting');
            this.setChannelsData(this.channelsData);

            console.log(this.channelFilters);
         }
      }

  }


  networkToggle = (networkId, add) => {
      if(add) {
        this.networkFilters = this.networkFilters.concat([networkId]);
      } else {
        this.networkFilters = this.networkFilters.filter((value, index, arr) => {
            return value !== networkId;
        });
      }
  }

  allNetworksSelected = () => {
      return this.networkFilters.length === this.networksData.length;
  }


  setChannelsData = (data, forcedChannelName=null) => {
    this.channelsData = data;

    let defaultChannel = [data[0].id];
    if(forcedChannelName !== null) {
        let foundChannel = this.channelsData.find((channel) => {
            return channel.slug === forcedChannelName;
        });
        if(foundChannel !== undefined) {
            defaultChannel[0] = foundChannel.id;
        } else {
            console.warn("Got a slug but could not find forced channel named "+forcedChannelName+", forcing default instead");
        }
    }
    this.channelFilters = defaultChannel;
  }

  setNetworksData = (data) => {
    this.networksData = data;
    this.networkFilters = data.map((item) => {
        return item.id;
    });
  }

  getUrlEncodedNetworkFilters = () => {
      if(this.networkFilters.length === this.networksData.length || this.networkFilters.length === 0) {
        return '';
      } else {
         return encodeURIComponent(this.networkFilters.join());
      }
  }

  getUrlEncodedChannelFilters = () => {
      return encodeURIComponent(this.channelFilters.join());
  }



  getChannel = (channelId) => {
      return this.channelsData.find((channel) => {
          return channel.id === channelId;
      });
  }

  getNetwork = (networkId) => {
    return this.networksData.find((network) => {
        return network.id === networkId;
    });
  }


  reset = (globalSearch=false) => {
      console.log("Filters manager reset "+globalSearch);
      this.networkFilters = this.networksData.map((item) => {
        return item.id;
      });
      this.channelFilters = [this.channelsData[0].id];
      if(globalSearch === false) {
          console.log("clearing search filter");
          this.searchFilter = '';
      }
      this.leaderboardFilter = '';
      this.timelineFilter = 0;
      this.sinceFilter = 0;
  }



  forceChannel = (id) => {
    this.channelFilters = [];
    this.channelToggle(id, true);
  }

  resetChannels = () => {
    this.channelFilters = [];

  }


  getSearchFilter = () => {
    return encodeURIComponent(this.searchFilter);
  }

}

export default FiltersManager;