import React, {Component} from 'react';
import './campaigns-page.css';


class CampaignsPage extends Component {




	render() {
		return (
			<div className="campaigns-page page">
					<div className="content">
					</div>
			</div>
		);
	}
}



export default CampaignsPage;