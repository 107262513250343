import React, { Component } from 'react';
import "./app-loader.css";


let hints = [
	"Loading initial data",
	"Thinking deeply",
	"Brewing coffee",
	"Planting trees",
	"Raising mountains",
	"Filling oceans",
	"Producing clouds"

];

class AppLoader extends Component {

	constructor(props) {
		super(props);

		this.interval = null;

		this.state = {
			currentHintIndex: 0
		}
	}

	componentDidMount() {
		this.interval = setInterval(this.onInterval.bind(this), 2000);
	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}


	onInterval() {
		var newIndex = (this.state.currentHintIndex + 1) % (hints.length);
		this.setState({currentHintIndex: newIndex});
	}


  render() {
  	return(
  		<div className="app-loader">
  			<div className="content">
  				<img className="logo" src="/app/images/logo512x512.png" alt="Loading..."/>
  				<p>{hints[this.state.currentHintIndex]}...</p>
  				{/* Force loads webfonts */}
  				<i className="fas fa-heart" /><i className="fab fa-facebook" /><i className="far fa-angry" />
  			</div>
  		</div>
  	);
  }
}

export default AppLoader;
