import React, {Component} from 'react';
import './leaderboard-page.css';


import Overlay from '../../common/overlay';
import UserOverlayContent from './user-overlay-content';

import UserInfos from '../user-infos';


class LeaderboardPage extends Component {

	constructor(props) {
		super(props);
		this.state = {
			overlayContent: null
		}
	}

	openUserOverlay = (user) => {
		this.setState({overlayContent: <UserOverlayContent dataLoad={this.props.dataLoad} user={user} /> })
	}

	closeOverlay = () => {
		this.setState({overlayContent:null});
	}

	render() {
		return (
			<div className="leaderboard-page page">
					<div className="content">
						{
							this.props.dataLoad.leaderboardData.map((user) => {
								return (<div className="leaderboard-user" key={user.id}><UserInfos data={user} userClicked={this.openUserOverlay.bind(this, user)} /></div>)
							})
						}
						
					</div>
					<Overlay content={this.state.overlayContent} exit={this.closeOverlay} rounded={true}/>
			</div>
		);
	}
}



export default LeaderboardPage;