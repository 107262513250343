import LanguageManager from './language-manager';
import FiltersManager from './filters-manager';
import BreadcrumbManager from './breadcrumb-manager';


import LanguageJSON from '../data/languages.json';
import moment from 'moment';

class DataLoad {

  constructor(props) {
      this.userData = null;
      this.userGameLevelData = null;
      this.userGroupData = null;
      this.userStats = null;
      this.dictionary = null;
      this.globalSettings = null;
      this.languages = LanguageJSON;
      this.originalFeedItems = null;
      this.feedTypes = null;
      this.reposFeedTypes =  null;
      this.channels = [];
      this.telexItems = [];

      this.footerData = null;

      this.rewards = null;

      this.forcedChannelName = null;

      this.leaderboardData = [];

      this.lastItemIndex = 40;

      this.languageManager = null;

      this.filtersManager = new FiltersManager();
      this.breadcrumbManager = new BreadcrumbManager({filtersManager: this.filtersManager});

      this.wsEndPoint = process.env.REACT_APP_API_ENDPOINT;
      this.apiClient = process.env.REACT_APP_API_CLIENT_ID;


      this.apiCode = null;
      this.apiBearer = localStorage.getItem("api_bearer");


      this.refreshController = null;
  }

  start() {
    if(this.apiBearer === null) {
      return this.getOauthCodePromise().then((response) => {
          this.apiCode = response.code;
          return this.getOauthAccessTokenPromise(response.code).then((response) => {
              this.apiBearer = response.token;
              localStorage.setItem("api_bearer", this.apiBearer);
              return this.loadMandatoryDataPromise();
          });
      });

    } else {
        return this.loadMandatoryDataPromise();
    }

   
     // return Promise.all(promises);
      //Load global settings
      //Load languages
      //Load feeditem
  }


  loadMandatoryDataPromise = () => {
      return this.getCurrentUserPromise().then(() => { return this.getGlobalSettingsPromise().then( () => { return Promise.all(this.getSecondaryPromises()).then(() => { return Promise.all(this.getTertiaryPromises()) }) }  )  });
  }


  updateUserLanguage = () => {
      this.userData.language.id = this.languageManager.currentLanguage;
      return this.fetch('user/current/', {'method': 'PUT', 'body': JSON.stringify(this.userData)});
  }

  saveUser = () => {
    return this.fetch('user/current/', {'method': 'PUT', 'body': JSON.stringify(this.userData)});
  }


  getOauthCodePromise = () => {
      return this.fetch('oauth?client_id=' + this.apiClient, {'method': 'GET'});
  }


  getOauthAccessTokenPromise = (code) => {
      return this.fetch('oauth/access_token?client_id=' + this.apiClient + '&code=' + code, {'method': 'GET'});
  }


  getSecondaryPromises = () => {
    return [this.getDictionaryPromise(), this.getReposFeedType(this.userData.id), this.getChannelPromise()];
  }

  getTertiaryPromises = () => {
      return [this.getLanguagesPromise(), this.getFeedItemsPromise(), this.getFeedTypePromise(), this.getFooterLinksPromise()];
  }


  getGlobalSettingsPromise() {
      return this.fetch('globalsettings').then(data =>  {
        this.globalSettings = data; 
        if(this.globalSettings.reactEnableCache == false)  {
          //navigator.serviceWorker.controller.postMessage('enable-service-cache'); 
        } else {
          //console.log("SERVICE CACHE DISABLED !"+this.globalSettings.reactEnableCache);
        }
        return data; 
      });
  }

  getLanguagesPromise() {
      return new Promise((resolve, reject) => {
        resolve();
      });
  }

  getFeedItemsPromise() {
    var defaultChannel = this.getMasterChannel().id;
    if(this.forcedChannelName !== null) {
       defaultChannel = this.filtersManager.channelFilters[0];
       console.log("Forced channel with id "+defaultChannel);
    }

    return this.fetch('feeditem?pagingFrom=1&pagingTo=40&filterfeedtypeid=&filterchannelid='+defaultChannel+'&filterfilterid=&filterfromdays=0&filteruntildays=0&filterishidden=2&filteriscombined=3&filtersearchtext=&_=1552931999498').then(data => {this.originalFeedItems = data; return data;});
  }


  getFeedTypePromise() {
    return this.fetch('feedtype').then(data => {this.feedTypes = data; this.filtersManager.setNetworksData(data)});
  }

  getChannelPromise() {
    return this.fetch('channel').then((response) => { return this.setupChannels(response).then(() => { this.sortTelexItems(); }); });
  }


  getCurrentUserPromise() {

    return this.fetch('user/current/').then((response) => {this.userData = response; return response;});
    
  }

  getCampaign = (id) => {
      return this.fetch("campaign/"+id);
  }

  getFooterLinksPromise = () => {
    return this.fetch('labelfooternavigation').then((response) => { this.footerData = response; return response; });
  }


  getReposFeedType = (userId) => {
    if(userId === undefined) {
      userId = this.userData.id;
    }
    return this.fetch('user/'+userId+'/repostfeedtype').then(data => {this.reposFeedTypes = data});
  }

  getNextPage = (withHidden = false) => {
      console.log("next page "+withHidden); 
      var hidden = "2";
      var options = {};
      if(withHidden === true) {
        hidden = "0";
      }
      var combined = "3";
      if(withHidden === true) {
        combined = "0";
      }

      if(this.refreshController !== null) {
        options["signal"] = this.refreshController.signal;
      }


    console.log(this.filtersManager.searchFilter);

    return this.fetch('feeditem?pagingFrom='+(this.lastItemIndex+1)+'&pagingTo='+(this.lastItemIndex+40)+'&filterfeedtypeid='+this.filtersManager.getUrlEncodedNetworkFilters()+'&filterchannelid='+
      this.filtersManager.getUrlEncodedChannelFilters()+'&filterfilterid='+this.filtersManager.leaderboardFilter+'&filterfromdays='+this.filtersManager.timelineFilter+'&filteruntildays='+this.filtersManager.sinceFilter+'&filterishidden='+hidden+'&filteriscombined='+combined+'&filtersearchtext='+this.filtersManager.getSearchFilter()+'&_=1552931999498', options).then(data => {if(this.lastItemIndex == 0) { this.originalFeedItems = data; } this.lastItemIndex += 40; this.refreshController = null; return data; });

  }


  getChannelFeedItems = (channel) => {
      return this.fetch('feeditem?pagingFrom=1&pagingTo=24&filterfeedtypeid=&filterchannelid='+channel.id+'&filterfilterid=&filterfromdays=0&filteruntildays=0&filterishidden=2&filteriscombined=3&filtersearchtext=&_=1552931999498').then(data => { this.telexItems = this.telexItems.concat(data); });
  }


  getCampaignList = () => {
    return this.fetch('campaign');
  }


  getItemsForCampaign = (campaignId) => {
    return this.fetch('feeditem?filtercampaignid='+campaignId);
  }


  addItemToCampaign = (item, campaign) => {
      return this.fetch('campaign/'+campaign.id+'/feeditem/'+item.id, {method: 'POST'}).then((res) => {item.campaign = campaign});
  }

  removeItemFromCampaign = (item, campaign) => {
      return this.fetch('campaign/'+campaign.id+'/feeditem/'+item.id, {method: 'DELETE'}).then((res) => {item.campaign.id = 0});
  }

  getItemActivity = (item) => {
    return this.fetch('feeditem/'+item.id+'/activity');
  }

  getFeedItem = (id) => {
    return this.fetch('feeditem/'+id);
  }

  displayItems = (items, hidden) => {
        var promises = [];

        items.forEach((item) => {
          item.isHidden = hidden;
          promises.push(this.updateItem(item));
        });

        return Promise.all(promises);
  }

  hideItems = (items) => {
      return this.displayItems(items, true);

  }

  showItems = (items) => {
    return this.displayItems(items, true);
  }

  updateItem = (item) => {
    return this.fetch('feedItem/'+item.id, {method:'PUT', body: JSON.stringify(item)});
  }




  refresh = (withHidden = false) => {
    
      if(this.refreshController !== null) {
          console.log("canceling previous request");
          this.refreshController.abort();
          this.refreshController = null;
      }
      try {
        this.refreshController = new AbortController();
      } catch(error) {
        this.refreshController = null;
      }
  
      this.lastItemIndex = 0;
      return this.getNextPage(withHidden);
  }


  setupChannels = (data) => {
      this.channels = data; 
      this.filtersManager.setChannelsData(data, this.forcedChannelName);
      if(this.hasTelexChannel()) {
          let telexChannels = this.getTelexChannels();
          let telexItemsPromises = telexChannels.map((channel) => {
              return this.getChannelFeedItems(channel);
          });
          return Promise.all(telexItemsPromises);
      }
      return new Promise((resolve, reject) => {
          resolve();
      });
  }


  sortTelexItems = () => {
      this.telexItems = this.telexItems.sort((a, b) => {
          if(a.isFeature > 0) {
              if(b.isFeature > 0) {
                  if(a.isFeature < b.isFeature) {
                     return -1;
                  } else if(a.isFeature > b.isFeature) {
                    return 1;
                  } else {
                     return moment(b.datePublished).diff(moment(a.datePublished));
                  }
              } else {
                  return -1;
              }
          } else {
              if(b.isFeature > 0) {
                return 1;
              } else {
                  return moment(b.datePublished).diff(moment(a.datePublished));
              }
          }
      });
  }


  hasTelexChannel = () => {
      return this.getTelexChannels().length > 0;
  }

  getTelexChannels = () => {
     return this.channels.filter((channel) => {
          return channel.isTelex;
     });
  }

  getMasterTelexChannel = () => {

    let telexChannels = this.getTelexChannels();

    return telexChannels[telexChannels.length - 1];
  }

  getMasterChannel = () => {
      let channel = this.channels.find((channel) => {
          return channel.isDefault === true;
      });

      if(channel !== undefined) {
          return channel;
      } else {
        return this.channels[0];
      }
  }


  getUserGroups = () => {
    return this.fetch('usergroup').then((response) => {this.userGroupData = response; return response });
  }


  


  processUserGroups = (response) => {
    this.userGroupData = response;
    return response;
  }

 /* getUserSocialAccounts = () => {
    return this.fetch("socialaccount/"+this.userData.id);
  }*/

  getUserGameLevel = () => {
    return this.fetch("gamelevel").then((response) => { this.userGameLevelData = response; return response; });
  }

  getLeaderboard = (from, to) => {

    var requestUrl = this.wsEndPoint.replace("api/", "")+'apireporting/leaderboard/list?topx=20&datefrom='+from+'&dateto='+to;

    return fetch(requestUrl, this.getHeaders()).then(res => { return res.json(); }).then((result) => { this.leaderboardData = result; return result;});
  }

  getUserActivity = () => {
    return this.fetch("activity/currentuser/?top=100000");
  }

  getUserActivityFeed = () => {
    return this.fetch("activity/any/?top=25");
  }



  fetch = (url, options={}) => {
      let finalOptions = {...options, ...this.getHeaders()};
      return fetch(this.wsEndPoint+url, finalOptions).then(res => { return res.json(); }).catch((error) => { return console.error('fetch failed url : '+url, finalOptions); throw error; });
  }


  getHeaders = () => {
     var headers =  {
      //'Accept': 'application/json',
      //'Content-Type': 'application/json',
    }
    if(this.apiBearer !== null) {
        headers['Authorization'] =  'Bearer ' + this.apiBearer;
        return {'headers': headers};
    }
    return {};
    
  }

  isFeedLinked = (feed) => {
      let foundFeed = this.userData.socialAccounts.find((account) => {
          return account.feedTypeId === feed.feedTypeId;
      });

      if(foundFeed !== null) {
          return foundFeed.linked;
      }

      return false;
  }

  isFeedLinkable = (feed) => {
      let foundFeed = this.reposFeedTypes.find((reposFeed) => {
          return reposFeed.feedTypeId === feed.feedTypeId;
      });    
  }

  uploadUserAvatar = (file, onUploadProgress) => {
    const formData = new FormData();
    formData.append('document', file);

    let finalOptions = {...{method: "POST", body:formData}, ...this.getHeaders()};
    return fetch(this.globalSettings.urlUserAsset.replace(/:id_user/, this.userData.id), finalOptions);
  }

  getUserLatestBadge = () => {
      return this.userGameLevelData[this.userData.badgeCount - 1];
  }

  getTopPosts = () => {
    return this.fetch("feeditem?pagingFrom=1&pagingTo=20&filterfeedtypeid=&filterchannelid=&filterfilterid=1&filterfromdays=90&filteruntildays=0&filterishidden=2&filteriscombined=3&filtersearchtext=&_=1552931999498")
  }


  getGroupPosts = () => {
     return this.fetch("feeditem?pagingFrom=1&pagingTo=20&filterfeedtypeid=&filterchannelid=&filterfilterid=&filterfromdays=0&filteruntildays=0&filterishidden=2&filteriscombined=3&filtersearchtext=&_=1552931999498")
  }


  getCampaignPosts = () => {
    return this.fetch("feeditem?pagingFrom=1&pagingTo=20&filterfeedtypeid=&filterchannelid=&filterfilterid=5&filterfromdays=0&filteruntildays=0&filterishidden=2&filteriscombined=3&filtersearchtext=&_=1552931999498")
    
  }

  updateSecondaryGroups = (groups) => {
    return this.fetch("usergroup?type=secondary", {method: "PUT", body: JSON.stringify(groups)});
  }

  getRewards = () => {
      return this.fetch("feeditem?filterfilterid=8").then((res) => { this.rewards = res; return res;});
  }

  createPost = (formData) => {
     return this.fetch("feeditem", {method: "POST", body: formData});
  }

  getFeeds = () => {
    return this.fetch("feed");
  }

  trackItemClick = (item) => {
      let finalOptions = this.getHeaders();
      return fetch(this.wsEndPoint+'trackingfeeditem/'+item.id, finalOptions).then(res => { return null; });
  }


  getCurrentUserStats = () => {
      if(this.userData !== null) {
          var requestUrl = this.wsEndPoint.replace("api/", "")+'apireporting/globalstatsuser/get';
          var formData = new FormData();

          formData.append("dateFrom", "");
          formData.append("dateTo", "");
          formData.append("datePresets", [0]);
          formData.append("userGroups", [0]);
          formData.append("feedTypes", []);
          formData.append("feeds", []);
          formData.append("channels", []);
          formData.append("campaigns", []);
          formData.append("gameLevels", []);
          formData.append("users", this.userData.id);
          formData.append("feedItems", []);


          let data  = {
            dateFrom: "",
            dateTo: "",
            datePresets: [0],
            userGroups: [0],
            feedTypes: [],
            feeds: [],
            channels: [],
            campaigns: [],
            gameLevels: [],
            users: [this.userData.id],
            feedItems: []
          };

          let options = {method: 'POST', dataType: 'json', body: JSON.stringify(data) };
          return fetch(requestUrl, {...options, ...this.getHeaders()}).then(res => { return res.json(); }).then((result) => { this.userStats = result; return result;});
 
      }



      /*{"dateFrom":"","dateTo":"","datePresets":[0],"userGroups":[0],"feedTypes":[],"feeds":[],"channels":[],"campaigns":[],"gameLevels":[],"users":[2067],"feedItems":[]}*/
      return null;
  }


  hasAdminAccess = () => {
    return this.userData !== null && (this.userData.rights.campaign_view === true || this.userData.rights.channel_view === true || this.userData.rights.feed_view === true || this.userData.rights.post_view === true || this.userData.rights.reporting_view === true || this.userData.rights.user_view === true || this.userData.rights.usergroup_view === true)
  }

  deletePost = (item) => {
    return this.fetch('feeditem/'+item.id, {"method": "DELETE"});
  }


  getDictionaryPromise = () => {
      return this.fetch('dictionary').then(res => { this.dictionary = res.dictionaryEntries; this.languageManager = new LanguageManager({entries:this.dictionary, defaultLanguage: this.userData.language.id, appName:this.globalSettings.siteName}); return res});
  }

}

export default DataLoad;