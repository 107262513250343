import React, { Component } from 'react';

import { BrowserRouter as Link } from "react-router-dom";

import ComponentLoader from '../common/component-loader';
import './tab-list.css';

class TabList extends Component {

	constructor(props) {
		super(props);

		this.state = {
			"selectedCategory": 0,
			"items": this.props.items,
			"loaded": this.props.items.length > 0
		};


		  this._isMounted = false;

	}


	componentDidMount = () => {
		this._isMounted = true;
		if(this.state.loaded === false) {
			this.fetchSelectedCategory();
		}
	}


	componentWillUnmount = () => {
		this._isMounted = false;
	}

	onCategorySelected = (selectedCategory) => {
		if(this.state.selectedCategory === selectedCategory || this.state.loaded === false) {
			return;
		}
		this.props.categorySelected(selectedCategory);
		this.setState({"selectedCategory": selectedCategory, "loaded": false}, () => {
				this.fetchSelectedCategory();
		});
	}

	fetchSelectedCategory = () => {
		let category = this.props.categories[this.state.selectedCategory];
		category.fetchItems().then((items) => {
			if(this._isMounted) {
				return this.setState({loaded: true, items: items.slice(0,12)});
			}
		});
	}

	onItemClicked = (item) => {
		this.props.itemClicked(item);
	}

	renderHeader = () => {
		let categories = this.props.categories.map((category, index) => {
			let selected = index === (this.state.selectedCategory);
			return this.renderCategory(category, index, selected);
		});

		return (
			<div className="header">
				{categories}{this.renderFullListLink()}
			</div>
		);
	}

	renderCategory = (category, index, selected) => {
		var categoryClass = "category";
		if(selected === true) {
			categoryClass += " selected";
		}
		return (<div key={"category-"+index} className={categoryClass} onClick={this.onCategorySelected.bind(this, index)}><div className="category-title">{category.title}</div></div>);
	}


	renderContent = () => {
		var content = null;
		if(this.state.items.length > 0) {
 			content = this.state.items.map((item, index) => {
				return this.renderItem(item, index);
			});
		} else if(this.state.loaded) {
			content = this.renderEmptyResult();
		}
		return (
			<div className="items">
					{content}
					{this.renderLoader()}
			</div>
		);
	}


	renderEmptyResult = () => {
		return (<div className="no-result" dangerouslySetInnerHTML={ {__html: this.props.emptyText}}></div>);
	}


	renderItem = (item, index) => {
		return (
				<div key={"tablist-item-"+item.id+"-"+index} className="item" onClick={this.onItemClicked.bind(this, item)}>
					<div className="item-wrapper">
					</div>
					<div className="item-content">
						{this.renderItemContent(item)}
					</div>
				</div>);
	}


	renderItemContent = (item) => {
		if(item.imageURL) {
			return (<img src={process.env.REACT_APP_BASE_URL+item.imageURL}></img>);
		} else {
			return this.props.renderFallbackContent(item);
		}
	}

	renderFullListLink = () => {
		return (<div className="full-list category"><Link to={this.props.fullListPath}><i className="fas fa-list" /></Link></div>);
	}

	renderLoader = () => {
	    if(!this.state.loaded) {
	      return <ComponentLoader />;
	    } else {
	      return null;
	    }
  	}

	render() {
		return (
			<div className="tab-list">
			{this.renderHeader()}
			{this.renderContent()}
			</div>
		);
	}

}

export default TabList;