import React, {Component} from 'react';
import ProfileCard from './profile-card';


class GamificationRules extends Component {
	

	constructor(props){
		super(props);

		this.localize = props.localize;

	}



	render() {

		if(this.props.localize !== null) {
			const wysiwyg1 = this.props.localize.get(162);
	    	const wysiwyg2 = this.props.localize.get(163);
	    	let wysiwygAll = null;

	    	if (wysiwyg1 != null && wysiwyg1.length > 6) {
	        	wysiwygAll = wysiwyg1;
	    	}

	    	if (wysiwyg2 != null && wysiwyg2.length > 6) {
	        	wysiwygAll += '<p>&nbsp;</p>';
	        	wysiwygAll += wysiwyg2;
	    	}

	    	const rawHtml = { __html: wysiwygAll }

	    	/*let content = "";

	    	if (wysiwygAll != null && wysiwygAll.length > 0 && this.props.localize !== null) {

	    		content = <div dangerouslySetInnerHTML={rawHtml} />;
	    	} else {
	    		content = "";
	    	}*/
		

		if (wysiwygAll != null && wysiwygAll.length > 0 && this.props.localize !== null) {
				return (
		        	<div dangerouslySetInnerHTML={rawHtml} />
				);
	    	} else {
	    		return "";
	    	}
	    } else {
	    	return "";
	    }
	}
}


export default GamificationRules