import React, {Component} from 'react';
import './badges-page.css';
import Badge from './badge-item';

import Overlay from '../../common/overlay';
import BadgeOverlayContent from './badge-overlay-content';

class BadgesPage extends Component {



	constructor(props) {
		super(props);
		this.state =  {
			overlayContent: null
		}
	}


	closeOverlay = () => {
		this.setState({overlayContent: null});
	}


	showBadgeOverlay = (badge) => {
		let overlayContent = <BadgeOverlayContent badge={badge} />;
		this.setState({overlayContent: overlayContent});
	}

	render() {

		return (
			<div className="badges-page page">
				<div className="badges">
				{ 
					this.props.badges.map((badgeData, index) => {
						let isUnlocked = (this.props.badgeCount - 1) >= index;
						return (<Badge key={badgeData.id} data={badgeData} unlocked={isUnlocked} onBadgeClicked={this.showBadgeOverlay} />)
					})
				}
				</div>
				<Overlay content={this.state.overlayContent} exit={this.closeOverlay} />
			</div>
		);
	}
}



export default BadgesPage;