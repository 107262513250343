import React, { Component } from 'react';
import "./campaign-item.css";

import TextFormatter from '../../logic/text-formatter';

class CampaignItem extends Component {

	constructor(props) {
		super(props);


		this.state = {
			selected: this.props.defaultSelected,

		}

		this.textFormatter = new TextFormatter();

	}


	onChange = (event) => {
		if(event.target.checked) {
			if(!this.props.defaultselected) {
				this.props.itemsManager.registerForAdd(this.props.data);
			} else {
				this.props.itemsManager.unregisterForAdd(this.props.data);
			}
		} 
		else {
			if(this.props.defaultSelected) {
				this.props.itemsManager.registerForRemove(this.props.data);
			} else {
				this.props.itemsManager.unregisterForRemove(this.props.data);
			}

		}

		this.setState({selected: !this.state.selected});
	}


	renderCounts = () => {

      var likeCount = "";
      if(this.props.data.likeCounter > 0) {
        likeCount = <span className="count"><i className="fa fa-heart" />{TextFormatter.formatNumber(this.props.data.likeCounter)}</span>
      }	

      var viewCount = "";
      if(this.props.data.viewCounter > 0) {
        viewCount = <span className="count"><i className="fa fa-eye" />{TextFormatter.formatNumber(this.props.data.viewCounter)}</span>
      }

      var shareCount = "";
      if(this.props.data.shareCounter > 0) {
        shareCount = <span className="count"><i className="fa fa-share-alt" />{TextFormatter.formatNumber(this.props.data.shareCounter)}</span>
      }


      return <div className="counts">{likeCount}{viewCount}{shareCount}</div>;
	}


	render() {

		let networkClass = this.props.data.parentFeed.parentFeedType.fontAwesome;

		return <div className="campaign-item content-list row no-gutters">
			<div className="col col-2">
				<div className="selection">
					<input type="checkbox" onChange={this.onChange} checked={this.state.selected} />
				</div>
			</div>
			<div className="col col-6 infos" dangerouslySetInnerHTML={ {__html: unescape(this.props.data.title)} }>
			</div>
			<div className="col col-2">
				<div className="social-icon"><i className={networkClass} /></div>
			</div>
			<div className="col col-2">
					{this.renderCounts()}
			</div>
			
		</div>;
	}


}



export default CampaignItem;