class EditManager {


	constructor() {
		this.selectedItems = [];
	}


	onItemSelected = (item) => {
		let found = this.selectedItems.find((selectedItem) => {
			return selectedItem.id === item.id;
		});

		if(found === undefined) {
			this.selectedItems.push(item);
			console.log("adding item");
			console.log(this.selectedItems);
		}


	}

	onItemDeselected = (item) => {
		console.log("removing item "+item.id);
		
		this.selectedItems = this.selectedItems.filter((filterItem, index) => {
			return item.id !== filterItem.id;
		});
		console.log(this.selectedItems);		
	}

	onItemToggle = (item, state) => {
		return (state === true) ? this.onItemSelected(item) : this.onItemDeselected(item); 
	}

	reset = () => {
		this.selectedItems = [];
	}


	findItem = (item) => {
		let found = this.selectedItems.find((selectedItem) => {
			return item.id === selectedItem.id;
		});
		//console.log(item);
		//console.log(this.selectedItems);
		return found !== undefined;
	}

}

export default EditManager;