import React, { Component } from 'react';
import UserInfos from './user-infos';

class MyUser extends Component {


	render() {
		return (
			<div className="my-user">
				<UserInfos data={this.props.data} localize={this.props.localize} />
				<div className="controls">
					<div className="control">
					{ this.renderInviteLink() }
					</div>
					<div className="control">
					{ this.renderSuggestLink() }
					</div>
					<div className="control">
					<button className="btn btn-primary" onClick={this.props.onGroupClicked}>{this.props.localize.get(145)}</button>
					</div>
				</div>
			</div>
		);
	}




	renderInviteLink = () => {
		return <a href={this.inviteLink()} className="btn btn-info"><i className="fa fa-user-plus" /> {this.props.localize.get(164)}</a>
	}


	renderSuggestLink = () => {
		return <a href={this.suggestLink()} className="btn btn-info"><i className="fa fa-edit" /> {this.props.localize.get(165)}</a>
	}



	inviteLink = () => {
		return "mailto:?subject="+this.props.localize.get(164);
	}

	suggestLink = () => {
		return "mailto:?subject="+this.props.localize.get(165);
	}

}

export default MyUser;
