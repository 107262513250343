import React, { Component } from 'react';

class Breadcrumb extends Component {

	constructor(props) {
		super(props);


		this.state = {
			text: this.props.text
		}

	}


	updateText = (text) => {
		this.setState({text: text});
	}

	render() {

		return (
			<div id="breadcrumb" onClick={this.props.refresh}><span className="text">{this.state.text}</span><span className="refresh-button"><i className="fas fa-redo"></i></span></div>
		)
	}
}



export default Breadcrumb;