import React, { Component } from 'react';
import "./tree-select.css";

import TreeSelectItem from './tree-select-item';



class TreeSelect extends Component {

	constructor(props) {
		super(props);
    this.treeManager = this.props.treeManager;

	}

  componentDidMount = () => {

  }


  onTreeItemSelected = (item) => {
    this.props.treeManager.onItemSelected(item);
        this.forceUpdate();
  }

  onTreeItemDeselected = (item) => {
    this.props.treeManager.onItemDeselected(item);
        this.forceUpdate();
  }


  onTreeItemOpened = (item) => {
    this.props.treeManager.onItemOpened(item);
        this.forceUpdate();
  }

  onTreeItemClosed = (item) => {
    this.props.treeManager.onItemClosed(item);
    this.forceUpdate();
  }


  renderTree = () => {
    return this.props.treeManager.userGroups.map((group) => {
        if(this.props.treeManager.isItemRendered(group) === false) {
          return null;
        }
        return (<TreeSelectItem key={group.id} treeManager={this.props.treeManager} level={0} item={group} isPrimary={this.props.treeManager.isPrimaryItem(group)} isSelected={this.props.treeManager.isItemSelected(group)} isSelectable={this.props.treeManager.isItemSelectable(group)} isOpened={this.props.treeManager.isItemOpened(group)} isVisible={this.props.treeManager.isItemVisible(group)} onItemOpened={this.onTreeItemOpened} onItemClosed={this.onTreeItemClosed} onItemSelected={this.onTreeItemSelected} onItemDeselected={this.onTreeItemDeselected} />);
    });
  }

  render = () => {
    return (
    <div className="tree-select">
      {this.renderTree()}
    </div>);
  }


}

export default TreeSelect;
