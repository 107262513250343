import React, { Component } from 'react';
import "./navbar.css";

import { withRouter } from 'react-router-dom';



class Navbar extends Component {

  constructor(props) {
      super(props);


  }


  onClick = () => {

    const {url} = this.props;
    if(this.props.forceLogout === true) {
      this.forceLogout();
      return;
    }
    if(url !== undefined) {
        this.props.history.push(url);
    } else {
      this.props.history.goBack();
    }
  }



  forceLogout = () => {
    localStorage.removeItem("api_bearer");
    window.location = process.env.REACT_APP_BASE_URL+"logout?bearer="+this.props.dataLoad.apiBearer;
  }


  renderBackArrow = () => {
    return (
        <div className="back" onClick={this.onClick}><i className="fas fa-arrow-left" /></div>
    );
  }

  renderPageTitle = () => {
    return (
      <div className="page-title">{this.props.title}</div>
    )
  }



  renderContent = () => {
      if(this.props.content) {
          return this.props.content;
      } else {
          return (
              <div className="content">
                {this.renderBackArrow()}
                {this.renderPageTitle()}
              </div>
          );
      }
  }

  render = () => {
     return (
        <div className="navbar">
          {this.renderContent()}
        </div>
      );
  }
}

export default withRouter(Navbar);
