import React, { Component } from 'react';

import './wall-loader.css';
import './wall-item.css';


class WallLoader extends Component {

  constructor(props) {
    super(props);

    this.loaderRef = React.createRef();

    this.state = {
       'loading': false,
       'empty': false,
    }  
  }

  didScroll = (y, scrollHeight) => {

    let element = this.loaderRef.current;

    if(!this.state.loading && !this.state.empty && scrollHeight-y-document.documentElement.clientHeight <= element.offsetHeight / 2) {
        this.setState({'loading': true});
        this.props.nextPage().then(res => { this.didFinishLoading(res); });
    }
  }


  didFinishLoading = (res) => {
    this.setState({'loading': false, 'empty': false});
    if(res.length === 0) {
       this.setState({empty: true});
    }
  }

  componentDidMount() {
  	this.props.bindScroll(this.didScroll.bind(this));
  }


  renderContent =  () => {
      if(this.state.loading === true) {
        return <img className="logo" src="/app/images/logo512x512.png" alt="Loading..."/>;
      } else if(this.state.empty === true) {
         return <div>Terminé <i className="fa fa-stop-circle" /></div>;
      } else {
        return "";
      }
  }


  render() {
  	let loadingClass = this.state.loading ? "loader loading" : "loader";

    return (<div className="item">
            <div>
          <div ref={this.loaderRef} className="wall-item wall-loader">
          <div className="content">
    			 <div className={loadingClass}>{this.renderContent()}</div>
          </div>
          </div>
    	</div></div>);
  }

}



export default WallLoader;