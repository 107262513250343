import React, {Component} from 'react';


class ActivityFeedItem extends Component {


	render() {

				console.log(JSON.stringify(this.props.data));

		let activityTitle = { __html: this.props.data.FeedItem.title };
		let activityHtml = { __html: this.props.data.FeedItem.body };

		return (
			<div className="activity-feed-item row">
				<div className="col-2"><img alt="activity" src={this.props.data.FeedItem.imageURL}></img></div>
				<div className="col-10 activity-content">
					<div className="activity-title" dangerouslySetInnerHTML={activityTitle}></div>
					<div className="activity-text" dangerouslySetInnerHTML={activityHtml}></div>

				</div>
				<div className="col-12">
					<hr />
				</div>
			</div>

		);
	}
}


export default ActivityFeedItem;