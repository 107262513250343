 import React, { Component } from 'react';
import "./share-overlay.css";

class ShareOverlay extends Component {

  constructor(props) {
      super(props);

      this.state = {
          active: false
      }
  }


  close = () => {
    this.props.onClose();
  }


  render() {


      var overlayClass = "share-overlay";
      if(this.props.active) {
        overlayClass += " active";
      }



      return (
          <div className={overlayClass} onClick={this.close}>
            <div className="content">{this.props.children}</div>
          </div>
      );
  }

}

export default ShareOverlay;