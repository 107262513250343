import React, { Component } from 'react';
import "./campaign-content.css";
import Campaign from './campaign';

class CampaignContent extends Component {

	constructor(props) {
		super(props);


		this.state = {
			loaded: false,
			data: null,
		}
	}


	componentDidMount() {
		this.props.dataLoad.getCampaign(this.props.item.campaign.id).then((res) => {
			this.setState({loaded: true, data: res});
		});
	}


	removeItemFromCampaign = () => {
		this.props.removeItem(this.props.item, this.state.data);
	}


	renderFooter = () => {
		var removeButton = "";
		let adminLevel = this.props.dataLoad.userData.adminLevel;

		if(this.props.dataLoad.userData.rights.campaign_edit === true && this.props.removeItem !== undefined) {
			removeButton = <div className="btn btn-danger" onClick={this.removeItemFromCampaign}>{this.props.dataLoad.languageManager.get(85)}</div>;
		}

		return (<div className="footer">{removeButton}</div>);
	}


	renderCounters = () => {

          var likeCount = "";
          if(this.state.data.likeCounter > 0) {
            likeCount = <span className="count"><i className="fa fa-heart" />{this.state.data.likeCounter}</span>
          }

          var viewCount = "";
          if(this.state.data.viewCounter > 0) {
            viewCount = <span className="count"><i className="fa fa-eye" />{this.state.data.viewCounter}</span>
          }

          var shareCount = "";
          if(this.state.data.shareCounter > 0) {
            shareCount = <span className="count"><i className="fa fa-share-alt" />{this.state.data.shareCounter}</span>
          }


          return (<div className="counters">{likeCount}{viewCount}{shareCount}</div>);
	}


	renderContent = () => {
		return (<div className="campaign-content">
			<div className="header">
				<div className="title">{this.props.dataLoad.languageManager.get(50)}</div>{this.renderCounters()}
			</div>
			<hr />
			<div className="body">
			<Campaign campaign={this.state.data} dataLoad={this.props.dataLoad} />
			</div>
			{this.renderFooter()}
		</div>)
	}


	render = () => {
		if(this.state.data !== null) {
			return this.renderContent();
		}
		return null;
	}

}


export default CampaignContent;