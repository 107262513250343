import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import App from './App';
//import * as serviceWorker from './serviceWorker';



ReactDOM.render(<App />, document.getElementById('root'));


function displayUpdateNotification(registration, worker) {
	console.log("Posting update");
	window.postMessage("update-available");
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
console.log("Should register service worker");

//serviceWorker.register({onUpdate: displayUpdateNotification});




/*window.addEventListener('beforeinstallprompt', (e) => {
	e.preventDefault();
  	console.log("Install prompt !!");
  	e.prompt();
});*/




