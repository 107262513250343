import React, { Component } from 'react';
import Channel from '../menu/channel';
import Filter from '../menu/filter';
import Network from '../menu/network';
import NetworkFiltersToggle from '../menu/network-filters-toggle';
import Breadcrumb from '../menu/breadcrumb';
import './top-menu.css';

import { BrowserRouter as Router, Route, Link, withRouter } from "react-router-dom";


class TopMenu extends Component {

  constructor(props) {
    super(props);

    this.state = {
      filtersMenuActive: false,
      languageMenuActive: false,
      mobileMenuActive: false,
      sinceTextId: 68,
      menuKey: new Date().getTime() / 1000,
      enabled: true,
    };


    this.selectedLeaderboardRef = React.createRef();
    this.selectedTimelineRef = React.createRef();
    this.selectedSinceRef = React.createRef();

    this.selectedLanguageRef = React.createRef();

    this.networkToggleRef = React.createRef();
    this.networkRefs = this.props.dataLoad.filtersManager.networksData.map((network) => {
          return React.createRef();
    });

    this.breadcrumbRef = React.createRef();
    this.searchRef = React.createRef();
    this.fakeAdmin = false;

  }


  refresh = () => {
      //this.closeMenus();
      this.props.refreshWall(true);

  }


  showCreatePostModal = () => {
      this.closeMenus();
      this.props.showCreatePostModal();
  }


  closeMenus = () => {
      this.setState({filtersMenuActive: false, languageMenuActive: false});
  }


  componentDidMount() {

  }

  toggleFiltersMenu = () => {
    this.setState({filtersMenuActive: !this.state.filtersMenuActive, languageMenuActive: false, mobileMenuActive: false});
  }


  toggleLanguageMenu = () => {
    this.setState({languageMenuActive: !this.state.languageMenuActive, filtersMenuActive: false, mobileMenuActive: false});
  }


  toggleMobileMenu = () => {
    this.setState({mobileMenuActive: !this.state.mobileMenuActive, languageMenuActive: false, filtersMenuActive: false});
  }



  activateLeaderboardFilter = (component) => {
      console.log("Leaderboard filter !");
      this.selectedLeaderboardRef.current.disable();
      this.selectedLeaderboardRef.current = component;
      this.props.dataLoad.filtersManager.leaderboardFilter = component.filterId;
      this.updateBreadcrumb();
  }

  deactivateLeaderboardFilter = (component) => {

  }


  activateTimelineFilter = (component) => {
    this.selectedTimelineRef.current.disable();
    this.selectedTimelineRef.current = component;
    this.props.dataLoad.filtersManager.timelineFilter = component.filterId;
    this.updateBreadcrumb();
  }

  activateSinceFilter = (component) => {
    this.selectedSinceRef.current.disable();
    this.selectedSinceRef.current = component; 
    this.props.dataLoad.filtersManager.sinceFilter = component.filterId;
    this.setState({sinceTextId: component.props.textId});
    this.updateBreadcrumb();
  }

  deselectAllNetworks = (except=null) => {
    console.log("deselect all networks");
      this.networkRefs.forEach((ref, index) => {
          if(except == null || except !== ref.current.props.network) {
            ref.current.disable();
          }
      });
      this.updateBreadcrumb();
  }

  selectAllNetworks = () => {
      this.networkRefs.forEach((ref, index) => {
          ref.current.activate();
      });
      this.updateBreadcrumb();
      this.refresh();
  }

  onNetworkToggle = (network) => {
      console.log("network toggle ");
      /*if(this.props.dataLoad.filtersManager.allNetworksSelected()) {
          this.networkToggleRef.current.setState({allSelected: true});
      } else {
          this.networkToggleRef.current.setState({allSelected: false});
      }*/
      if(this.props.dataLoad.filtersManager.allNetworksSelected()) {
          this.deselectAllNetworks(network);
          this.networkToggleRef.current.setState({allSelected: false});
      }
      this.updateBreadcrumb();
      this.refresh();
  }

  deactivateTimelineFilter = (component) => {

  }

  toggleChannel = () => {

  }

  updateBreadcrumb = () => {
	  if(this.breadcrumbRef.current !== null) {
		this.breadcrumbRef.current.updateText(this.breadcrumbText());
	  }
  }


  breadcrumbText = () => {
      var sinceText = this.props.dataLoad.languageManager.get(this.selectedSinceRef.current.props.textId);
      var leaderboardText = this.props.dataLoad.languageManager.get(this.selectedLeaderboardRef.current.props.textId);
      var timelineText = this.props.dataLoad.languageManager.get(this.selectedTimelineRef.current.props.textId);

      return this.props.dataLoad.breadcrumbManager.getBreadcrumbText(sinceText, leaderboardText, timelineText);
  }

  defaultbreadcrumbText = () => {
      var sinceText = this.props.dataLoad.languageManager.get(68);
      var leaderboardText = this.props.dataLoad.languageManager.get(36);
      var timelineText = this.props.dataLoad.languageManager.get(23);

      return this.props.dataLoad.breadcrumbManager.getBreadcrumbText(sinceText, leaderboardText, timelineText);
  }

  updateSearch = () => {
    console.log(this.searchRef.current.value);
      this.props.dataLoad.filtersManager.searchFilter = this.searchRef.current.value;
      this.closeMenus();
      this.refresh();
  }

  updateGlobalSearch = () => {
      this.props.dataLoad.filtersManager.searchFilter = this.searchRef.current.value;

      this.closeMenus();
      this.resetFilters(true);
  }

  logoMainAction = () => {
    if(this.props.returnOnLogoClicked !== undefined && this.props.returnOnLogoClicked === true) {
        this.returnToWall();
    } else {
      this.resetFilters();
    }
  }


  returnToWall = () => {
    this.props.history.push('/');
  }


  resetFilters = (globalSearch=false) => {
      console.log("reset filters "+globalSearch);
      console.log(globalSearch);
      this.props.dataLoad.filtersManager.reset(globalSearch);
     // this.searchRef.current.value = '';
      this.setState({menuKey: new Date().getTime() / 1000});
      this.refresh();


  }

  toggleEdit = () => {
    //this.props.toggleEdit();
  }

  toggleAdmin = () => {
      window.open("/admin?bearer="+this.props.dataLoad.apiBearer);
  }


  changeLanguage = (languageId) => {
      this.props.dataLoad.languageManager.changeLanguage(languageId);
      this.props.dataLoad.updateUserLanguage();
      //this.props.dataLoad.languageManager.currentLanguage = languageId;
      this.props.onLanguageChange();
	  this.updateBreadcrumb();

  }


  presentLogin = () => {
      this.props.presentLogin();
  }

  deleteUserCache = () => {
    if('serviceWorker' in navigator) {
      caches.keys().then(function(cacheNames) {
        console.log(cacheNames);
        cacheNames.forEach(function(cacheName) {
          if(cacheName.includes('/ws/api/oauth')) {
            console.log('FOUND OAUTH CACHE TO DELETE !');
            caches.delete(cacheName);
          }
        });
      });
      caches.delete('/ws/api/user/current/');
    }
  }

  logout = () => {
    localStorage.removeItem("api_bearer");
    this.deleteUserCache();
    window.location = process.env.REACT_APP_BASE_URL+"logout?bearer="+this.props.dataLoad.apiBearer;
  }


  renderLoggedOutOptions =  (mobile=false) => {
      return (<span className="login" onClick={this.presentLogin}><i className="fas fa-sign-in-alt"></i></span>);
  }


  renderMenuControls = () => {
    if(this.props.isWall) {
      var filterClass = "filters";
      if(this.state.filtersMenuActive) {
        filterClass += " active";
      }

      var languageClass = "language";
      if(this.state.languageMenuActive) {
        languageClass += " active";
      }

     return (<React.Fragment><div className="d-none d-lg-inline"><span className={filterClass} onClick={this.toggleFiltersMenu}><i className="fa fa-filter"></i></span><span className="spacer"></span>{this.renderLogOptions(false)}<span className="spacer"></span><span className={languageClass} onClick={this.toggleLanguageMenu}><i className="fa fa-globe"></i></span></div><div className="d-lg-none">{this.renderMobileMenu()}</div></React.Fragment>);
      } else {
        return this.renderProfileControls();
      }   
  }

  renderGamificationControl = () => {
        if(this.props.dataLoad.globalSettings.gamification === true) {
              return (<React.Fragment><span className="gamification"><Link to='/profile/gamification'><i className="fas fa-dice"></i></Link></span><span className="spacer"></span></React.Fragment>);
        }
        return null;
  }

  renderProfileControls = () => {
    return (<React.Fragment><div className="profile-menu">{this.renderGamificationControl()}
         <span className="logout" onClick={this.logout}><i className="fas fa-sign-out-alt"></i></span><span className="spacer"></span><span className="language" onClick={this.toggleLanguageMenu}><i className="fa fa-globe"></i></span></div></React.Fragment>);
  }

  renderMobileMenu = () => {
    let createPost = null;
    if(this.props.dataLoad.userData.rights.post_create === true || this.props.dataLoad.userData.rights.wall_creator === true) {
      createPost = (<React.Fragment><span className="new-post"><span onClick={this.showCreatePostModal}><i className="fa fa-edit"></i></span></span><span className="spacer"></span></React.Fragment>);

    }

    return (<div className="mobile-menu d-lg-none">
        <div className="mobile-menu-row"><span className="filters" onClick={this.toggleFiltersMenu}><i className="fa fa-filter"></i></span><span className="spacer"></span>{createPost}{this.renderLogOptions(true)}<span className="spacer"></span><span className="language" onClick={this.toggleLanguageMenu}><i className="fa fa-globe"></i></span></div>
    </div>);
  }


  newPost = () => {
    window.open("/admin/feeditem");
  }

  renderLoggedInOptions = (mobile=false) => {
    var options = "";
    var adminOption = "";
    var editOption = "";
    let createPost = null;
    if((this.props.dataLoad.userData.rights.post_create === true || this.props.dataLoad.userData.rights.wall_creator === true) && !mobile) {
      createPost = (<React.Fragment><span className="new-post"><span onClick={this.showCreatePostModal}><i className="fa fa-edit"></i></span></span><span className="spacer"></span></React.Fragment>);
    }
    if(this.props.dataLoad.hasAdminAccess() === true && !mobile) {
      adminOption = (<React.Fragment><span><span className="edit"><i className="fa fa-cog" onClick={this.toggleAdmin}></i></span><span className="spacer"></span></span></React.Fragment>);
    }
    if(this.props.dataLoad.userData.rights.wall_editor === true || this.fakeAdmin) {
       editOption = (
       <React.Fragment>
          <span className="edit"><i className="fas fa-pencil-alt" onClick={this.props.toggleEdit}></i></span>
          <span className="spacer"></span>
        </React.Fragment>);
    }
    return (
      <React.Fragment>
          {createPost}
          {editOption}
          {adminOption}
         <span className="user"><Link to="/profile"><i className="fa fa-user"></i></Link></span>
         <span className="spacer"></span>
         <span className="logout" onClick={this.logout}><i className="fas fa-sign-out-alt"></i></span>
      </React.Fragment>
      );
  }


  renderLogOptions = (mobile=false) => {
    if(!this.props.dataLoad.userData.isAnonymous || this.fakeAdmin) {
      console.log("Logged in / Is mobile ? "+mobile);
      return this.renderLoggedInOptions(mobile);
    } else {
      return this.renderLoggedOutOptions(mobile);
    }
  }

  renderLanguaguesMenu = () => {
        var languageMenuClass = "row no-gutters";
        if(this.state.languageMenuActive && this.props.active) {
          languageMenuClass += " active";
        }
    return (
          <div id="language-menu" className={languageMenuClass}>
            <div className="close"><i className="fa fa-times-thin" onClick={this.closeMenus}></i></div>
            <div className="languages category">
              <div className="row">
                <div className="col-12 col-xl-1"><label>{this.props.dataLoad.languageManager.get(21)}</label></div>
                <div className="col-12 col-xl-11 filters">
                      {

                        this.props.dataLoad.languages.map((language, index) => {
                            let itemClass  = "language filter";
                            if(this.props.dataLoad.languageManager.currentLanguage === language.id) {
                               itemClass += " active";
                            }

                            let append = (index < this.props.dataLoad.languages.length - 1) ? <span className='filter-separator'> | </span> : "";

                            return (<div key={index}  style={{display: "flex"}}><div className={itemClass} onClick={this.changeLanguage.bind(this, language.id)}>{language.name}</div>{append}</div>)
                        })
                      }
                </div>
              </div>
            </div>
        </div>
    )
  }

  renderFiltersMenu = () => {
    if(this.props.isWall) {
        var filtersMenuClass = "row no-gutters";
        if(this.state.filtersMenuActive && this.props.active) {
          filtersMenuClass += " active";
        }

        return (
        <div id="filters-menu" className={filtersMenuClass}>
          <div className="close"><i className="fa fa-times-thin" onClick={this.closeMenus}></i></div>
          <div className="network-filters category">
            <div className="row">
            <div className="col-12 col-xl-1"><label>{this.props.dataLoad.languageManager.get(4)}</label></div>
            <div className="col-12 col-xl-11 networks">
                <NetworkFiltersToggle ref={this.networkToggleRef} dataLoad={this.props.dataLoad} selectAllNetworks={this.selectAllNetworks} deselectAllNetworks={this.deselectAllNetworks} />
               {
                  this.props.dataLoad.feedTypes.map((item, index) => {
                       
                        return (
                            <Network ref={this.networkRefs[index]} key={item.id} network={item} dataLoad={this.props.dataLoad} refresh={this.refresh} onToggle={this.onNetworkToggle} />
                        )
                  })
                }
              </div>
              </div>
          </div>

          <div className="channel-filters category">
            <div className="row">
              <div className="col-12 col-xl-1"><label>{this.props.dataLoad.languageManager.get(5)}</label></div>
              <div className="col-12 col-xl-11 channels">
                {

                  this.props.dataLoad.channels.map((item, index) => {
                      if(item.isTelex === false) {

                        var active = false;
                        if(this.props.dataLoad.filtersManager.channelFilters.includes(item.id)) {
                          active = true;
                        }
                        return (
                          <Channel key={item.id} channel={item} dataLoad={this.props.dataLoad} active={active} refresh={this.refresh} onToggle={this.updateBreadcrumb}></Channel>
                        )
                     }
                     return ("")
                  })
                }
              </div>
            </div>
          </div>
            <div className="leaderboard-filters category">
              <div className="row">
                <div className="col-12 col-xl-1"><label>{this.props.dataLoad.languageManager.get(7)}</label></div>
                <div className="col-12 col-xl-11 filters">
                      <Filter ref={this.selectedLeaderboardRef} active={true} onActivateFilter={this.activateLeaderboardFilter} filterId={''} dataLoad={this.props.dataLoad} refresh={this.refresh} textId={36}>{this.props.dataLoad.languageManager.get(36)}</Filter>
                      <Filter onActivateFilter={this.activateLeaderboardFilter} filterId={1} dataLoad={this.props.dataLoad} refresh={this.refresh} textId={31}>{this.props.dataLoad.languageManager.get(31)}</Filter>
                      <Filter onActivateFilter={this.activateLeaderboardFilter} filterId={2} dataLoad={this.props.dataLoad} refresh={this.refresh} textId={32}>{this.props.dataLoad.languageManager.get(32)}</Filter>
                      <Filter onActivateFilter={this.activateLeaderboardFilter} filterId={3} dataLoad={this.props.dataLoad} refresh={this.refresh} textId={33}>{this.props.dataLoad.languageManager.get(33)}</Filter>
                      <Filter onActivateFilter={this.activateLeaderboardFilter} filterId={4} dataLoad={this.props.dataLoad} refresh={this.refresh} textId={34}>{this.props.dataLoad.languageManager.get(34)}</Filter>
                      <Filter onActivateFilter={this.activateLeaderboardFilter} filterId={6} dataLoad={this.props.dataLoad} refresh={this.refresh} textId={335}>{this.props.dataLoad.languageManager.get(335)}</Filter>
                      <Filter onActivateFilter={this.activateLeaderboardFilter} filterId={5} dataLoad={this.props.dataLoad} refresh={this.refresh} textId={35}>{this.props.dataLoad.languageManager.get(35)}</Filter>
                </div>
              </div>
            </div>
             <div className="since-filters category">
              <div className="row">
                <div className="col-12 col-xl-1"><label>{this.props.dataLoad.languageManager.get(169)}</label></div>
                <div className="col-12 col-xl-11 filters">
                      <Filter ref={this.selectedSinceRef} active={true} onActivateFilter={this.activateSinceFilter} filterId={0} dataLoad={this.props.dataLoad} refresh={this.refresh} textId={68}>{this.props.dataLoad.languageManager.get(68)}</Filter>
                      <Filter onActivateFilter={this.activateSinceFilter} filterId={1} dataLoad={this.props.dataLoad} refresh={this.refresh} textId={69}>{this.props.dataLoad.languageManager.get(69)}</Filter>
                      <Filter onActivateFilter={this.activateSinceFilter} filterId={7} dataLoad={this.props.dataLoad} refresh={this.refresh} textId={70}>{this.props.dataLoad.languageManager.get(70)}</Filter>
                      <Filter onActivateFilter={this.activateSinceFilter} filterId={14} dataLoad={this.props.dataLoad} refresh={this.refresh} textId={71}>{this.props.dataLoad.languageManager.get(71)}</Filter>
                      <Filter onActivateFilter={this.activateSinceFilter} filterId={30} dataLoad={this.props.dataLoad} refresh={this.refresh} textId={72}>{this.props.dataLoad.languageManager.get(72)}</Filter>
                      <Filter onActivateFilter={this.activateSinceFilter} filterId={90} dataLoad={this.props.dataLoad} refresh={this.refresh} textId={73}>{this.props.dataLoad.languageManager.get(73)}</Filter>
                      <Filter onActivateFilter={this.activateSinceFilter} filterId={180} dataLoad={this.props.dataLoad} refresh={this.refresh} textId={74}>{this.props.dataLoad.languageManager.get(74)}</Filter>
                      <Filter onActivateFilter={this.activateSinceFilter} filterId={365} dataLoad={this.props.dataLoad} refresh={this.refresh} textId={75}>{this.props.dataLoad.languageManager.get(75)}</Filter>
                </div>
              </div>
            </div>
            <div className="timeline-filters category">
              <div className="row">
                <div className="col-12 col-xl-1"><label>{this.props.dataLoad.languageManager.get(408)}</label></div>
                <div className="col-12 col-xl-11 filters">
                      <Filter onActivateFilter={this.activateTimelineFilter} filterId={1} dataLoad={this.props.dataLoad} refresh={this.refresh} textId={24}>{this.props.dataLoad.languageManager.get(24)}</Filter>
                      <Filter onActivateFilter={this.activateTimelineFilter} filterId={7} dataLoad={this.props.dataLoad} refresh={this.refresh} textId={25}>{this.props.dataLoad.languageManager.get(25)}</Filter>
                      <Filter onActivateFilter={this.activateTimelineFilter} filterId={14} dataLoad={this.props.dataLoad} refresh={this.refresh} textId={26}>{this.props.dataLoad.languageManager.get(26)}</Filter>
                      <Filter onActivateFilter={this.activateTimelineFilter} filterId={30} dataLoad={this.props.dataLoad} refresh={this.refresh} textId={27}>{this.props.dataLoad.languageManager.get(27)}</Filter>
                      <Filter onActivateFilter={this.activateTimelineFilter} filterId={90} dataLoad={this.props.dataLoad} refresh={this.refresh} textId={28}>{this.props.dataLoad.languageManager.get(28)}</Filter>
                      <Filter onActivateFilter={this.activateTimelineFilter} filterId={180} dataLoad={this.props.dataLoad} refresh={this.refresh} textId={29}>{this.props.dataLoad.languageManager.get(29)}</Filter>
                      <Filter onActivateFilter={this.activateTimelineFilter} filterId={365} dataLoad={this.props.dataLoad} refresh={this.refresh} textId={30}>{this.props.dataLoad.languageManager.get(30)}</Filter>
                      <Filter ref={this.selectedTimelineRef} active={true} onActivateFilter={this.activateTimelineFilter} filterId={0} dataLoad={this.props.dataLoad} refresh={this.refresh} textId={23}>{this.props.dataLoad.languageManager.get(23)}</Filter>
                </div>
              </div>
            </div>
            <div className="search category">
              <div className="row">
                <div className="col-12 col-xl-1"><label>{this.props.dataLoad.languageManager.get(3)}</label></div>
                <div className="col-12 col-xl-11 filters">
                    <div className="input-group mb-0">
                          <input ref={this.searchRef} className="form-control" type="text" placeholder={this.props.dataLoad.languageManager.get(3)}></input>
                            <div className="input-group-append">
                              <button className="btn btn-secondary" type="button" onClick={this.updateSearch}>OK</button>
                              <button className="btn btn-secondary global-search" type="button" onClick={this.updateGlobalSearch}>{this.props.dataLoad.languageManager.get(20)}</button>
                            </div>
                          
                    </div>
                </div>
              </div>
            </div>
             <div className="reset category">
              <div className="row">
                <div className="col-12 col-md-8 footers">
                  {this.props.dataLoad.footerData.map((footer, index) => {
                    return (<div className="footer-link" key={"footer-"+index}><a href={footer.url} target="_blank">{this.props.dataLoad.languageManager.get(footer.dictionaryId)}</a></div>);
                  })}
                </div>
                <div className="col-12 col-md-4 filters">
                    <button className="btn btn-secondary" onClick={this.resetFilters.bind(this, false)}><i className="fa fa-times mr-1"></i>{this.props.dataLoad.languageManager.get(98)}</button>
                    <button className="btn btn-secondary ml-1" onClick={this.closeMenus}>{this.props.dataLoad.languageManager.get(97)}</button>
                </div>
              </div>
            </div>
        </div>

        );
      }
      return null;

  }


  renderLeftMenu = () => {

    if(this.props.isWall) {

      var breadcrumbs = null;
      if(this.props.isWall) {
        breadcrumbs = <Breadcrumb ref={this.breadcrumbRef} text={this.defaultbreadcrumbText()} refresh={this.refresh} type="desktop" />;
      }
   
        return (
           <div className="owner-logo">
                <img className="logo" src={process.env.REACT_APP_BASE_URL+this.props.dataLoad.globalSettings.urlLogo} alt="" onClick={this.logoMainAction}></img>
                {breadcrumbs}
            </div>
        );

    } else {
      return this.renderBackToWall();
    }
  }


  renderBackToWall = () => {
      return (
          <div className="owner-logo" onClick={this.returnToWall}><i className="fas fa-arrow-left"></i><i className="fa fa-th pl-3"></i></div>
        );
  }

  render() {


    var mainMenuClass = "";
    if(this.props.active === false) {
      mainMenuClass += " inactive";
    }

    return (

      <div id="menu" key={this.state.menuKey}>
      <div id="main-menu" className={mainMenuClass}>
          <div className="row no-gutters">
            {this.renderLeftMenu()}
            <div className="controls">
                {this.renderMenuControls()}
            </div>
          </div>
      </div>
      {this.renderFiltersMenu()}
      {this.renderLanguaguesMenu()}
      </div>);
  }

}



export default withRouter(TopMenu);