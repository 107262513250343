import React, {Component} from 'react';
import './badge-overlay-content.css';
import ComponentOverlay from '../../common/component-overlay';

import TextFormatter from '../../../logic/text-formatter';


class BadgeOverlayContent extends Component {

	constructor(props) {
		super(props);

		this.state = {
			shareActive: this.props.shareActive ? this.props.shareActive : false,
			selectedNetwork: this.props.selectedNetwork ? this.props.selectedNetwork : null
		}
	} 

	getPictureUrl = () => {
		if(this.props.unlocked) {
			return this.props.badge.pictureUrlActivated;
		} else {
			return this.props.badge.pictureUrl;
		}
	}


	getBadgeName = () => {
		if(this.props.unlocked) {
			return this.props.badge.nameActivated;
		} else {
			return this.props.badge.name;
		}		
	}



	getBadgeContent = () => {
		return {__html: TextFormatter.decodeHTMLEntities(this.props.description, true)}
	}




	getBadgeNameClassName = () => {
		var className = "badge-name text-center";
		if(this.props.unlocked !== true || this.props.feedItem === undefined) {
			className += " no-share";
		}
		return className;
	}



	openShareOverlay = () => {
		this.setState({shareActive: true});
	}

	closeShareOverlay = () => {
		this.setState({shareActive: false});
	}


	renderShare = () => {
		console.log("render share badge");

		if(this.props.unlocked === true && this.props.feedItem !== undefined) {

			let content = null;
			if(this.state.selectedNetwork !== null) {
				content = this.renderShareActions();
			} else {
				content = this.renderShareInactive();
			}

			return (<div className="share">
						{content}
					</div>);
		}
		return null;
	}


	renderShareInactive = ()  => {
		return (
			<React.Fragment>
				<div className="share-title">{this.props.dataLoad.languageManager.get(266)}</div>
				<div className="share-icon" onClick={this.openShareOverlay}><i className="fas fa-share-alt" /></div>
			</React.Fragment>
		)
	}


	renderShareActions = () => {
      var validateButton = "";

      let feed = this.state.selectedNetwork;
      let feedClass = feed.fontAwesome;

      let linked = this.props.dataLoad.isFeedLinked(feed);

        console.log(linked+"?");

      if(linked === false) {
        
        validateButton = <a href={process.env.REACT_APP_BASE_URL+feed.shareUrl+"&bearer="+this.props.dataLoad.apiBearer} target="_blank" className="btn btn-success">{this.props.dataLoad.languageManager.get(47)} <i className={feedClass} /></a>
        window.current = {feedTypeReset: this.reloadFeedAndPresentShare.bind(this, feed) };
      } else  {

        var shareText = this.props.dataLoad.languageManager.get(46);
        if(feed.actionType == 2) {
          shareText = this.props.dataLoad.languageManager.get(45);
        }
        if(feed.actionType == 4) {
          shareText = this.props.dataLoad.languageManager.get(292);
        }


        validateButton = <button className="btn btn-success" onClick={this.doShare.bind(this, feed)}>{shareText} <i className={feedClass} /></button>
        window.wall = { currentModal: { repostCallback: this.props.repostCallback }};

      }

      return (
          <div className="network-footer"><button className="btn btn-danger" onClick={this.props.exit}>{this.props.dataLoad.languageManager.get(93)}</button>{validateButton}</div>
        );
	}

	reloadFeedAndPresentShare  = (feed) => {
		console.log("Callback !");
		let promises = [this.props.dataLoad.getCurrentUserPromise(), this.props.dataLoad.getReposFeedType()];
		Promise.all(promises).then(() => {
		 	this.networkSelected(feed);
		}).catch((error) => {
			console.log(error);
		});
	}


	renderShareOverlayContent = () => {
		let overlayContent = this.props.dataLoad.reposFeedTypes.map((feed, index) => {

		  if(this.props.feedItem === undefined || (this.props.feedItem.parentFeed.parentFeedType.id !== 4 && (feed.id === 400 || feed.id === 404))) {
		    return null;
		  }
		  let imageClass = feed.fontAwesome+" fa-fw";
		  let backgroundStyle = {backgroundColor: feed.color};

		  return (<div className="network" alt={feed.name} key={index}><div className="network-content"><div className="wrapper" style={backgroundStyle} onClick={this.networkSelected.bind(this, feed)}><i className={imageClass}></i></div></div></div>)
		});

		return overlayContent;
	}


  	doShare = (feed) => {
	    let iframeUrl = process.env.REACT_APP_BASE_URL+'repost?feedItemId='+this.props.feedItem.id+'&feedTypeId='+feed.id+'&activityType='+feed.actionType+'&bearer='+this.props.dataLoad.apiBearer;
     	this.props.doShare(iframeUrl ,this.props.feedItem, feed);
  	}


  	networkSelected = (feed) => {
	    console.log("network selected");
	    this.setState({selectedNetwork: feed, shareActive: false});
  	}



	render() {
		return (
			<div className="badge-container">
				<div className="badge-wrapper">
				<div className="badge-content">
					<img alt="badge" src={process.env.REACT_APP_BASE_URL+this.getPictureUrl()} />
					<div className="badge-points text-center">{this.props.badge.points}</div>
					<div className="badge-description text-center" dangerouslySetInnerHTML={this.getBadgeContent()}></div>
					<div className={this.getBadgeNameClassName()}>{this.getBadgeName()}</div>
					{this.renderShare()}
					<ComponentOverlay active={this.state.shareActive} exit={this.closeShareOverlay} specialClass="share-overlay">
						<div className="networks">
						{ this.renderShareOverlayContent() }
						</div>
					</ComponentOverlay>
				</div>
				</div>
			</div>
			
		);
	}
}



export default BadgeOverlayContent;