import React, { Component } from 'react';
import "./telex.css";



class Telex extends Component {

	constructor(props) {
		super(props);

		this.items = this.props.data;


	}


  componentDidMount = () => {
        var telexContent = window.document.getElementsByClassName('telex-content')[0];

        let time = telexContent.offsetWidth / 90;

       telexContent.style.animationDuration = time+'s';
  }



  render() {
      let ownerBgStyle = {backgroundImage: `url(${this.props.ownerImage})`}
      var telexContentClass = "telex-content";
      if(this.props.data.length === 1) {
          telexContentClass += " single";
      }

  	return(
  		<div id="telex">
        <div className="row no-gutters">
        <div className="owner-logo col-xs-0 col-lg-1">
          <div className="background" style={ownerBgStyle} onClick={this.props.toggleTelexChannel}>
          </div>
        </div>
  			<div className="col-xs-12 col-lg-10 slider">
            <div className={telexContentClass}>
             {
                this.props.data.map((item, index) => {
                    let html = {__html: item.title.replace(/\n/g, '') };

                    return (<div key={index} className="item" dangerouslySetInnerHTML={html}></div>)
                })
             }
             </div>
  			</div>
        <div className="logo col-xs-0 col-lg-1">
            <a href="https://go4.social" target="_blank">
            <span>Powered by </span>
            <img alt="owner" src="/app/images/go4social-logo-black.png" />
            </a>
        </div>
        </div>
  		</div>
  	);
  }
}

export default Telex;
