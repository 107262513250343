import React, {Component} from 'react';
import './gamification-page.css';


import GamificationRules from '../gamification-rules';

class GamificationPage extends Component {




	render() {
		return (
			<div className="gamification-page page">
					<div className="content">
							<GamificationRules localize={this.props.dataLoad.languageManager} />
					</div>
			</div>
		);
	}
}



export default GamificationPage;