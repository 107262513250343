import React, { Component } from 'react';
import {Router, Route, Link} from 'react-router-dom';
import './overview.css';

import UserGroupTreeManager from '../../logic/user-group-tree-manager';
//import { Button } from 'reactstrap';

class Overview extends Component {


	constructor(props) {
		super(props);

		this.treeManager = new UserGroupTreeManager(this.props.dataLoad.userGroupData);
	}

	goToLeaderboard = () => {

	}


	renderManageNetworks = () => {
		return (<Link to="/profile/networks"><button className="text-button"></button></Link>);
	}


	renderUserNetworks = () => {
		var elements =  this.props.dataLoad.userData.socialAccounts.filter((account) => { return account.linked && account.linkable }).map((account) => {
			var linkedStyle = {};
			//if(account.linked === true || account.feedTypeId === 5) {
				linkedStyle = {'color': account.feedTypeColor}
			//}
			return (<div className="network" key={"user-network"+account.feedTypeId}><i className={account.feedTypeFontAwesome} style={linkedStyle} /></div>)
		});
		//elements.push(this.renderManageNetworks());
		
		return (<Link to="/profile/edit"><div className="networks">{elements}</div></Link>);
	}


	renderUserAvatar = () =>  {
		return (<img alt="avatar" src={this.props.dataLoad.userData.pictureUrl} className="rounded-circle" />);
	}



	renderPrimaryGroup = () => {

		let masterGroupName = this.treeManager.getMasterGroup().name;
		//FOrce the primary group change
		this.props.dataLoad.languageManager.setPrimaryGroupName(masterGroupName);


		return (<h2 className="primary-group">
				<i className="fa fa-users" /><span className="group-name">{masterGroupName}</span>
			</h2>);
	}


	renderGroupInfos = () => {

		let masterGroup = this.treeManager.getMasterGroup();

		return(<div className="group-infos">
			{this.renderPrimaryGroup()}
			<div className="follows"><div className="count">{masterGroup.userCounter+' '+this.props.dataLoad.languageManager.get(293)}</div><Link to="/profile/leaderboard"><div className="group-avatars">{this.renderGroupAvatars()}</div></Link></div>
		</div>);
	}


	renderGroupAvatars = () => {
		if(this.props.leaderboard !== undefined) {
			let users = this.props.leaderboard.slice(0, 4);
			return users.map((user) => {
				return (<img key={"avatar-user-"+user.id}src={user.pictureUrl}></img>);
			});
		}
		return null;
	}

	renderLastBadge = () => {

		let selectedIndex = this.props.dataLoad.userData.badgeCount > 0 ? (this.props.dataLoad.userData.badgeCount - 1) : 0;
		let lastBadge = this.props.gameLevel[selectedIndex];


		return (<div className="last-badge">
					<img alt="badge" src={process.env.REACT_APP_BASE_URL.slice(0, -1)+lastBadge.pictureUrlActivated} /><h1 className="badge-name">{lastBadge.nameActivated}</h1>
				</div>);
	}


	renderStat = (stat) => {
		return (<div className="stat">
			<div className="value">
				{stat.value}
			</div>
			<div className="name">
				{stat.name}
			</div>
		</div>)
	}



	renderStats = () => {

		let viewAllStyle = {marginLeft:"auto"};/*, flexBasis:"100%", fontSize: "1.2em"};*/

		var gamePoints = null;
		if(this.props.dataLoad.globalSettings.gamification === true) {
			gamePoints	= this.renderStat({name: this.props.dataLoad.languageManager.get(272), value: this.props.dataLoad.userStats.totalPoints});
		}

		return (<div className="stats">
					{gamePoints}
					{this.renderStat({name: this.props.dataLoad.languageManager.get(273), value: this.props.dataLoad.userData.gameRank})}
					{this.renderStat({name: this.props.dataLoad.languageManager.get(274), value: this.props.dataLoad.userStats.totalVisits})}
					{this.renderStat({name: this.props.dataLoad.languageManager.get(275), value: this.props.dataLoad.userStats.followerCount})}
					{this.renderStat({name: this.props.dataLoad.languageManager.get(276), value: this.props.dataLoad.userStats.totalActions})}
					<div className="stat" style={viewAllStyle}><Link to="/profile/stats"><button className="text-button">View all</button></Link></div>
		</div>)
	}


	renderInviteAfriend = () => {
		let disabled = "";
		if(process.env.REACT_APP_INVITE_ID == undefined) {
			disabled = "disabled";
		}

		return <button className="btn btn-light" onClick={this.props.inviteAFriend} disabled={disabled}>{this.props.dataLoad.languageManager.get(14)} <i className="fa fa-plus"></i></button>
	}


	renderActions = () => {
		return (<div className="profile-actions"><div className="invite-a-friend">
			{this.renderInviteAfriend()}
		</div>
		<div className="share-content">
			<a className="btn btn-light" href={"mailto:"+this.props.dataLoad.languageManager.get(336)}>{this.props.dataLoad.languageManager.get(304)}</a>
		</div></div>);
	}




	render() {
		return (
			<div className="overview">
				<div className="wrapper">
				<div className="infos">
					<div className="user">
						<h1>{this.props.dataLoad.userData.name}</h1>
						{this.renderUserNetworks()}
						{this.renderGroupInfos()}
					</div>
					<div className="avatar">
						{this.renderUserAvatar()}
						<Link to="/profile/edit" className="btn btn-light">{this.props.dataLoad.languageManager.get(230)}</Link>
					</div>
				</div>
				{this.renderActions()}
				{this.renderStats()}
				</div>
			</div>
		);
	}

}

export default Overview;