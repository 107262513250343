 import React, { Component } from 'react';
import "./share-thanks-content.css";
import moment from 'moment';
import TextFormatter from '../../logic/text-formatter';
import { BrowserRouter as Router, Route, Link, withRouter } from "react-router-dom";

class ShareThanksContent extends Component {



  close = () => {
    this.props.onClose();
  }


  renderHeader = () => {
      if(this.props.dataLoad.globalSettings.gamification === true && !this.props.dataLoad.userData.isAnonymous) {
            return this.renderThanksPoints();
      } else  {
            return this.renderLogo();
      }
  }


  getMessageText = () => {
      let lm = this.props.dataLoad.languageManager;
      if(this.props.feed == undefined) {
          return lm.get(78) + " " + this.props.dataLoad.globalSettings.siteName;
      }

      if(this.props.feed.actionType == 2) {
          return lm.get(82);
      } else if(this.props.feed.actionType == 4) {
          return lm.get(83);
      } else {
        let baseMessage = this.getBaseMessage();
        var finalMessage = baseMessage;

        if(this.props.dataLoad.globalSettings.gamification === true) {
          if(this.props.dataLoad.userData.isAnonymous === true) {
            finalMessage = <React.Fragment>{finalMessage}{this.renderLogin()}</React.Fragment>;
          } else if(this.props.showPointsAction === undefined || this.props.showPointsAction === true) {
            finalMessage = <React.Fragment>{finalMessage}{this.renderScore()}<Link className="btn btn-info btn-circle ml-1" to="/profile/gamification"><i className="fa fa-info"></i></Link><div><Link className="btn btn-info mt-1" to="/profile">{this.props.dataLoad.languageManager.get(173)}</Link></div></React.Fragment>;
          }
        }

        return (<React.Fragment>
                  {finalMessage}  
                </React.Fragment>);
      }
  }

  presentLogin = () => {
      this.close();
      this.props.presentLogin(); 
  }

  renderLogin = () => {
      return (<React.Fragment> - {this.props.dataLoad.languageManager.get(86)}<br /><button onClick={this.presentLogin} className="btn btn-primary">{this.props.dataLoad.languageManager.get(87)}</button></React.Fragment>);
  }

  renderScore = () => {
      return ' - '+this.props.dataLoad.languageManager.get(81).replace('%d', this.props.data.repost.points);
  }

  getBaseMessage = () => {
      return <React.Fragment>{this.props.dataLoad.languageManager.get(78)+' '+this.props.feed.name}</React.Fragment>
  }


  renderContent = () => {
    return (
    <div className="share-thanks-texte">
          <div className="message">
            {this.getMessageText()}
          </div>
          <div className="date">{moment().format("L")}</div>
        </div>);
  }


  renderLogo = () => {
    return (<div className="share-thanks-logo">
          <img alt="site" src={this.props.dataLoad.globalSettings.urlLogoLogIn} />
        </div>);
  }


  renderThanksPoints = () => {
      return (<div className="share-thanks-points">
            {this.props.dataLoad.languageManager.get(80).replace('%d', this.props.data.repost.points)}
        </div>)
  }


  renderItem = () => {

    var image = null;
    if(this.props.item.imageURL !== '') {
        image = (<div className="img-cont"><img alt="post" src={this.props.item.imageURL} /></div>);
    }
    let networkClass = this.props.item.parentFeed.parentFeedType.fontAwesome;

    return (
      <div className="overlay-featured thanks">
          {image} 
        <div className="txt-cont">
          <div className="network-icon">
            <i className={networkClass} aria-hidden="true"></i>
          </div>
          <h1 dangerouslySetInnerHTML={{ __html: TextFormatter.decodeHTMLEntities(this.props.item.title, true)}}></h1>
          <p dangerouslySetInnerHTML={{ __html: TextFormatter.decodeHTMLEntities(this.props.item.body, true)}}></p>
          <div className="timer">
            {TextFormatter.formatDate(this.props.item, false, this.props.dataLoad.languageManager)}
          </div>
        </div>
     </div>);
  }

  renderGameRank = () => {
    if(this.props.dataLoad.globalSettings.gamification === true && !this.props.dataLoad.userData.isAnonymous) {
      let bgStyle = {backgroundImage: 'url('+this.props.dataLoad.userData.pictureUrl+')'};

      return (
          <table className="overlay-collection">
      <tbody>
        <tr className="overlay-row">
          <td className="with-user-pict">
            <div className="user-pict" style={bgStyle}></div>
          </td>
          <td>
            <span className="overlay-row-text"><i className="fa fa-star" aria-hidden="true"></i> { this.props.dataLoad.userData.gamePoints } { this.props.dataLoad.languageManager.get(79) }</span>
            <span className="overlay-row-text"><i className="fa fa-trophy" aria-hidden="true"></i> { this.props.dataLoad.userData.gameLevelName }</span>
            <span className="overlay-row-text"><i className="fa fa-cubes" aria-hidden="true"></i> {this.props.dataLoad.languageManager.get(273) } { this.props.dataLoad.userData.gameRank }</span>
          </td>
        </tr>
      </tbody>
    </table>
      );
    }
    return null;
  }

  render = () => {
      return (
          <div className="share-thanks-content">
              <div className="content">
              {this.renderHeader()}
              {this.renderContent()}
              </div>
              {this.renderItem()}
              {this.renderGameRank()}
          </div>
      );
  }

}

export default ShareThanksContent;