import React, {Component} from 'react';
import './edit-profile-page.css';

import Networks from '../networks';
import Overlay from '../../common/overlay';

import TreeSelect from '../../common/tree-select';


import UserGroupTreeManager from '../../../logic/user-group-tree-manager';

import EphemeralMessage from '../../common/ephemeral-message';


import ItemOverlay from '../../wall/item-overlay';

class EditProfilePage extends Component {

	constructor(props) {
		super(props);

		this.state = {
			avatarModalActive: false,
			groupModalActive: false,
			userDescription: this.props.dataLoad.userData.description,
			savingUserStatus: false,
			selectedItem: null
		}

		this.treeManager = new UserGroupTreeManager(this.props.dataLoad.userGroupData);
		this.props.dataLoad.languageManager.setPrimaryGroupName(this.treeManager.masterGroup.name);
		this.fileInputRef = React.createRef();
		this.statusMessageRef = React.createRef();
		this.inviteAFriendItem = null;
	}


	getInviteAFriendItem = () => {
		return this.props.dataLoad.getFeedItem(process.env.REACT_APP_INVITE_ID).then((inviteAFriendItem) => {
			this.inviteAFriendItem = inviteAFriendItem;
		});		
	}

	reloadUser = () =>  {
		return this.props.onReloadUser();
	}

    showChangeAvatarModal = () => {
    	this.toggleAvatarModal(true);
    }

    hideAvatarModal = () => {
    	this.toggleAvatarModal(false);
    }

    toggleAvatarModal = (show) => {
    	this.setState({avatarModalActive: show, groupModalActive: false});
    }


	renderGroupModal = () => {
		return (<Overlay content={this.renderGroupTree()} exit={this.toggleGroupModal} rounded={true} />);
	}

	saveUserGroups = () => {
		this.props.dataLoad.updateSecondaryGroups(this.treeManager.selectedItems).then(this.toggleGroupModal); 
	}


	renderGroupTree = () => {
		if(this.state.groupModalActive) {
			return (
				<React.Fragment>
					<div className="header">
						<div className="description">{this.props.dataLoad.languageManager.get(228)}</div>
						<div className="title">{this.props.dataLoad.languageManager.get(234)}</div>
					</div>
					<TreeSelect treeManager={this.treeManager} />
					<div className="footer"><button className="btn btn-success" onClick={this.saveUserGroups}>{this.props.dataLoad.languageManager.get(166)}</button></div>
				</React.Fragment>
				);
		} else {
			return null;
		}
	}



	toggleGroupModal = () => {
		this.setState({groupModalActive: !this.state.groupModalActive, avatarModalActive: false});
	}

	renderAvatarChangeModal = () => {	
		if(!this.state.avatarModalActive) {
			return null;
		} else {
			return (<Overlay content={this.renderAvatarChangeModalContent()} exit={this.hideAvatarModal} rounded={true} />);
		}
	}

	importFromAccount = (account) => {
		this.setAvatar(account.picture);
	}

	setAvatar = (picture) => {
		this.props.dataLoad.userData.pictureUrl = picture;
		this.props.dataLoad.saveUser().then(() => {
			this.hideAvatarModal();
		});
	}

//this.$el.on('change', '.media-input', function(e) { that.readfiles(e.currentTarget.files, 0);


	readFiles =  (event) => {
		this.props.dataLoad.uploadUserAvatar(this.fileInputRef.current.files[0]).then(response => { response.text().then(this.setAvatar)});
	}

	deleteAvatar = () => {
		this.props.dataLoad.userData.pictureUrl = '/asp/assets/img/no-user.jpg';
		this.props.dataLoad.saveUser().then(() => {
			this.hideAvatarModal();
		});
	}

	triggerInput = () => {
		this.fileInputRef.current.click();
	}

	renderAvatarChangeModalContent = () => {


		return (
			<React.Fragment>
			<div className="header"><div className="title">Photo de profil</div></div>
			<div className="change-list">
				<div className="list-item custom-avatar" onClick={this.triggerInput}>
					<i className="fas fa-camera fa-fw" />Ajouter / Modifier
					<form className="hidden">
						<input ref={this.fileInputRef} type="file" className="hidden" onChange={this.readFiles}></input>
					</form>
				</div>
				{
					this.props.dataLoad.userData.socialAccounts.map((socialAccount) => {
						if(socialAccount.linkable && socialAccount.linked) {
							return (<div className="list-item account" onClick={this.importFromAccount.bind(this, socialAccount)}><i className={socialAccount.feedTypeFontAwesome+" fa-fw"} />Importer de {socialAccount.feedTypeName}</div>);
						}
						return null;
					})
				}
				<div className="list-item delete text-danger" onClick={this.deleteAvatar}>Supprimer</div>
			</div>
			</React.Fragment>
		);
	}


	descriptionChanged = (event) => {
		this.setState({userDescription: event.target.value});
	}

	saveDescription = () => {
		this.setState({savingUserStatus: true},  () => {
			this.props.dataLoad.userData.description = this.state.userDescription;
			this.props.dataLoad.saveUser().then(() => {
				this.statusMessageRef.current.show();
				this.setState({savingUserStatus: false});
			});	
		});
	}

	renderStatusMessage = () => {
		return <EphemeralMessage ref={this.statusMessageRef} message={"Status sauvegardé"} />;
	}





  renderItemOverlay = () => {
      return <ItemOverlay item={this.state.selectedItem} footer={null} onClose={this.onCloseItemOverlay} dataLoad={this.props.dataLoad} doShare={this.doShare} onCampaignClick={this.onClickShowCampaign} repostCallback={this.repostCallback} onShareClicked={this.shareClicked} onCancelShare={this.onCancelShare} editable={false} />
  }


  onCloseItemOverlay = event => {
    this.setState({selectedItem: null});
  }



	render() {
		return (
			<div className="edit-profile-page page">
				<div className="avatar">
					<div className="avatar-wrapper" onClick={this.showChangeAvatarModal}>
						<img src={this.props.dataLoad.userData.pictureUrl}>
						</img>
						<div className="camera"><i className="fas fa-camera"></i></div>
					</div>
				</div>
				<div className="user-status">
					<label htmlFor="user-status">{ this.props.dataLoad.languageManager.get(231) }</label>
					<textarea disabled={this.state.savingUserStatus} name="user-status" className="form-control" placeholder={this.props.dataLoad.languageManager.get(299)} value={this.state.userDescription} onChange={this.descriptionChanged}></textarea>
					<div className="save-container">
						{this.renderStatusMessage()}
						<button className="btn btn-primary" onClick={this.saveDescription}  disabled={this.state.savingUserStatus}>{this.props.dataLoad.languageManager.get(166)}</button>
					</div>
				</div>
				<div className="group-controls">
					<div className="primary-group">
						<div className="label">{this.props.dataLoad.languageManager.get(146)}</div>
						<div className="title"><a href={"mailto:"+this.props.dataLoad.languageManager.get(336)} >{this.treeManager.getMasterGroup().name}<i className="fas fa-shield-alt ml-1"></i></a></div>
					</div>
					<div className="secondary-groups">
						<div className="label">{this.props.dataLoad.languageManager.get(145)}</div>
						<button className="edit-secondary-groups btn btn-primary" onClick={this.toggleGroupModal}>{this.props.dataLoad.languageManager.get(235)}<i className="fas fa-users ml-1"></i></button>
					</div>
				</div>
				<div className="networks-title">{this.props.dataLoad.languageManager.get(236)}</div>
				<Networks data={this.props.dataLoad.userData.socialAccounts} localize={this.props.dataLoad.languageManager} bearer={this.props.dataLoad.apiBearer} onReloadUser={this.reloadUser}/>
				{this.renderAvatarChangeModal()}
				{this.renderGroupModal()}
				{this.renderItemOverlay()}
			</div>
		);
	}
}



export default EditProfilePage;