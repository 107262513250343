import React, { Component } from 'react';
import './wall-item-edit.css';
import Checkbox from '../common/checkbox';

class WallItemEdit extends Component {

	constructor(props) {
		super(props);

		this.state = {
			selected: this.props.selected,
			combined: this.props.combined,
			hidden: this.props.hidden,
			campaign: null,
		}

	}


	onSelectionChange = (event) => {
		event.stopPropagation();
		this.props.onEditItemChange(this.props.item, !this.props.selected);
		//this.setState({selected: !this.state.selected});
	}


	renderHiddenStatus = () => {
			return (<i className="fa fa-eye-slash"></i>);
	}

	renderCombinedStatus = () => {
			return (<i className="fas fa-link"></i>);
	}


	renderGroupNumber = () => {
		if(this.props.combined === true) {
			let groupNumber = this.props.item.id;
			if(this.props.item.parentId > 0) {
				groupNumber = this.props.item.parentId;
			}

			return groupNumber;			
		}
		return null;
	}


	render() {

		var hiddenStatus = "";
		var combinedStatus = "";
		var iconClass = "icons";

		var editClass = "wall-item-edit";
		if(this.props.selected) {
			editClass += " selected";
		}
		if(this.props.hidden) {
			editClass += " hidden";
			hiddenStatus = this.renderHiddenStatus();
		}

		if(this.props.combined) {
			editClass += " combined";
			combinedStatus = this.renderCombinedStatus();
		}

			var groupClass = "group-number";
			if(this.props.combined && this.props.item.parentId === 0) {
				groupClass += " master";
				iconClass += " master";
			}



		return (
		<div className={editClass} onClick={this.onSelectionChange}>
			<div className={iconClass}>
				{hiddenStatus}
				{combinedStatus}
			</div>
			<div className={groupClass}>{this.renderGroupNumber()}</div>
			<Checkbox selected={this.props.selected} onChange={this.onSelectionChange} />
		</div>
		);
	}

}



export default WallItemEdit;