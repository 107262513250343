import React, {Component} from 'react';
import ActivityFeedItem from './activity-feed-item';


class ActivityFeed extends Component {


	render() {

		return (
			<div className="activity-feed">
				{
					this.props.data.map((activityData, index) => {
						return (
							<ActivityFeedItem key={index} data={activityData} />
						);
					})
				}
			</div>
		);
	}
}



export default ActivityFeed;