import React, { Component } from 'react';
import "./edit-group-item.css";

import TextFormatter from '../../logic/text-formatter';


class EditGroupItem extends Component {

	constructor(props) {
		super(props);

		this.state = {
			selected: this.props.selected
		}

	}

	componentDidMount() {
		

	}

	selectMaster = () => {
		this.props.onSelectMaster(this.props.item);
	}


	renderDate = () => {
		console.log(this.props.item);
		let finalText = TextFormatter.formatDate(this.props.item, false, this.props.dataLoad.languageManager);

		return finalText;
	}

	onChange = (event) => {
			event.stopPropagation();
			console.log("CHANGE");
			this.props.changeSelection(this.props.item);
			this.setState({selected: !this.state.selected});
	}


	render = () => {

		let item = this.props.item;
		var itemClass = "group-item";
		if(this.props.isMaster) {
			itemClass += " master";
		}

		let feedClass = this.props.item.parentFeed.parentFeedType.fontAwesome;
		      var likeCount = "";
      if(item.likeCounter > 0) {
        likeCount = <span className="count"><i className="fa fa-heart" />{TextFormatter.formatNumber(item.likeCounter)}</span>
      }

      var viewCount = "";
      if(item.viewCounter > 0) {
        viewCount = <span className="count"><i className="fa fa-eye" />{TextFormatter.formatNumber(item.viewCounter)}</span>
      }

      var shareCount = "";
      if(item.shareCounter > 0) {
        shareCount = <span className="count"><i className="fa fa-share-alt" />{TextFormatter.formatNumber(item.shareCounter)}</span>
      }

		return (
			<div className={itemClass}>

				<div className="group content-list row no-gutters">
					<div className="col col-1">
						<div className="selection">
							<input type="checkbox" onChange={this.onChange} checked={this.state.selected} />
						</div>
					</div>
					<div className="col col-1 network">
						<span><i className={feedClass} /></span>
					</div>
					<div className="col col-8 infos">
						<div className="title">
							<div className="text" dangerouslySetInnerHTML={ {__html: unescape(this.props.item.title)} }></div>
							<div className="time">
								{this.renderDate()}
							</div>
						</div>
					</div>
					<div className="col col-1">
							<div className="counts">{likeCount}{viewCount}{shareCount}</div>
					</div>
					<div className="col col-1">
							<i className="fa fa-star" onClick={this.selectMaster} />
					</div>
			
				</div>
				
			</div>
		);
	}

}



export default EditGroupItem;