import React, { Component } from 'react';

class Filter extends Component {


	constructor(props) {
		super(props);

		this.filterId = this.props.filterId;

		this.state = {
			active: this.props.active,
		}
	}


	toggle = () => {





		console.log("toggle filter");

		if(!this.state.active) {

			//this.props.dataLoad.filtersManager.leaderboardFilter = this.filterId;


			if(this.state.active && this.props.onDeactivateFilter !== undefined) {
				this.props.onDeactivateFilter(this);
			}

			if(!this.state.active && this.props.onActivateFilter !== undefined) {
				console.log("On activate filter");
				this.props.onActivateFilter(this);
			}
			this.setState({active: !this.state.active});

			this.props.refresh();
		}
	}


	disable = () => {
		this.setState({active: false});
	}


	render() {

		var filterClass = "filter";
		if(this.state.active) {
			filterClass += " active";
		}

		return (
			<div className={filterClass} onClick={this.toggle}>{this.props.children}</div>
		)
	}

}



export default Filter;