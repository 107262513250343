import React, { Component } from 'react';
//import { Card, CardBody, CardTitle, Fade } from 'reactstrap';

class ProfileCard extends Component {
	constructor(props) {
		super(props);

		this.state = {
			error: null,
			isLoaded: props.dataLoaded
		}
	}


	componentDidMount() {
		/*console.log("profile card did mount "+this.props.title+"/"+this.cardTitle);
		setTimeout(function() {
			this.setState({
				isLoaded: true
			});
		}.bind(this) , 2000);*/
	}


	componentDidUpdate(prevProps) {
		if(prevProps.dataLoaded === false && this.props.dataLoaded === true) {
			this.setState({
				isLoaded: true
			});
		}
	}

	render() {
		console.log("profile card render"+this.props.title);
		let content;
		let title = null;


		
		if(this.props.dataLoaded) {
			if(this.props.title !== undefined) {
				title = <div className="card-title"><h4>{this.props.title}</h4><hr /></div>;
			}
		 	content = this.props.children;
		} else {
			content = <div className="spinner"></div>;
		}

		return (
			<div className="card profile">
				<div className="card-body">
					{title}
				 	{content}
				</div>
			</div>	
		);
	}
}

export default ProfileCard;