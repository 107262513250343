import React, {Component} from 'react';
import './reward-overlay-content.css';

import TextFormatter from '../../../logic/text-formatter';
import ComponentOverlay from '../../common/component-overlay';

class RewardOverlayContent extends Component {


	constructor(props) {
		super(props);

		this.state = {
			shareActive: this.props.shareActive ? this.props.shareActive : false,
			selectedNetwork: this.props.selectedNetwork ? this.props.selectedNetwork : null
		}
	} 
	/*renderShare = () => {
		if(this.props.unlocked === true && this.props.feedItem !== undefined) {
			return (<div className="share">
						<div className="share-title">{this.props.dataLoad.languageManager.get(266)}</div>
						<div className="share-icon"><i className="fas fa-share-alt" /></div>
					</div>);
		}
		return null;
	}*/

	getRewardContent = () => {
		return {__html: TextFormatter.decodeHTMLEntities(this.props.reward.body, true)}
	}

	getRewardTitle = () => {
		return {__html: TextFormatter.decodeHTMLEntities(this.props.reward.title, true)}
	}


	openShareOverlay = () => {
		this.setState({shareActive: true});
	}

	closeShareOverlay = () => {
		this.setState({shareActive: false});
	}


	renderShare = () => {
		console.log("render share reward");

		if(this.props.reward !== undefined && this.props.dataLoad.userData.gamePoints >= this.props.reward.rewardPoints) {

			let content = null;
			if(this.state.selectedNetwork !== null) {
				content = this.renderShareActions();
			} else {
				content = this.renderShareInactive();
			}

			return (<div className="share">
						{content}
					</div>);
		}
		return null;
	}


	renderShareInactive = ()  => {
		return (
			<React.Fragment>
				<div className="share-title">{this.props.dataLoad.languageManager.get(266)}</div>
				<div className="share-icon" onClick={this.openShareOverlay}><i className="fas fa-share-alt" /></div>
			</React.Fragment>
		)
	}


	renderShareActions = () => {
      var validateButton = "";

      let feed = this.state.selectedNetwork;
      let feedClass = feed.fontAwesome;

      let linked = this.props.dataLoad.isFeedLinked(feed);

      if(linked === false) {
        
        validateButton = <a href={process.env.REACT_APP_BASE_URL+feed.shareUrl+"&bearer="+this.props.dataLoad.apiBearer} target="_blank" rel="opener" className="btn btn-success">{this.props.dataLoad.languageManager.get(47)} <i className={feedClass} /></a>
        window.current = {feedTypeReset: this.reloadFeedAndPresentShare.bind(this, feed) };
      } else  {

        var shareText = this.props.dataLoad.languageManager.get(46);
        if(feed.actionType == 2) {
          shareText = this.props.dataLoad.languageManager.get(45);
        }
        if(feed.actionType == 4) {
          shareText = this.props.dataLoad.languageManager.get(292);
        }


        validateButton = <button className="btn btn-success" onClick={this.doShare.bind(this, feed)}>{shareText} <i className={feedClass} /></button>
        window.wall = { currentModal: { repostCallback: this.props.repostCallback }};

      }

      return (
          <div className="network-footer"><button className="btn btn-danger" onClick={this.props.exit}>{this.props.dataLoad.languageManager.get(93)}</button>{validateButton}</div>
        );
	}

	  reloadFeedAndPresentShare  = (feed) => {
	      console.log("Callback !");
	      let promises = [this.props.dataLoad.getCurrentUserPromise(), this.props.dataLoad.getReposFeedType()];

	      Promise.all(promises).then(() => {
	          this.networkSelected(feed);
	      }).catch((error) => {
	        console.log(error);
	      });
	  }


	renderShareOverlayContent = () => {
		let overlayContent = this.props.dataLoad.reposFeedTypes.map((feed, index) => {

		  if(this.props.reward.parentFeed.parentFeedType.id !== 4 && (feed.id === 400 || feed.id === 404)) {
		    return null;
		  }
		  let imageClass = feed.fontAwesome+" fa-fw";
		  let backgroundStyle = {backgroundColor: feed.color};

		  return (<div className="network" alt={feed.name} key={index}><div className="network-content"><div className="wrapper" style={backgroundStyle} onClick={this.networkSelected.bind(this, feed)}><i className={imageClass}></i></div></div></div>)
		});

		return overlayContent;
	}

  	doShare = (feed) => {
      let iframeUrl = process.env.REACT_APP_BASE_URL+'repost?feedItemId='+this.props.reward.id+'&feedTypeId='+feed.id+'&activityType='+feed.actionType+'&bearer='+this.props.dataLoad.apiBearer;
      this.props.doShare(iframeUrl ,this.props.reward, feed);
  	}

  	networkSelected = (feed) => {
	    console.log("network selected");
	    this.setState({selectedNetwork: feed, shareActive: false});
  	}

  	renderPoints = () => {

  		if(this.props.reward.rewardPoints == 0) {
  			return null;
  		}
  		return (<div className="points">{this.props.reward.rewardPoints.toLocaleString()} {this.props.dataLoad.languageManager.get(79)}</div>);
  	}

	render() {
		return (
			<div className="reward">
					<div className="content">
						<img src={process.env.REACT_APP_BASE_URL+this.props.reward.imageURL}></img>
						{this.renderPoints()}
						<div className="body" dangerouslySetInnerHTML={this.getRewardContent()}></div>
						<div className="title"dangerouslySetInnerHTML={this.getRewardTitle()}></div>
						{this.renderShare()}
						<ComponentOverlay active={this.state.shareActive} exit={this.closeShareOverlay} specialClass="share-overlay">
							<div className="networks">
							{ this.renderShareOverlayContent() }
							</div>
						</ComponentOverlay>
					</div>
			</div>
			
		);
	}
}

export default RewardOverlayContent;