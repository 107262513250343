import React, { Component } from 'react';
//import { Button } from 'reactstrap';
import Badge from './badge';


class Badges extends Component {

	componentDidMount() {
		console.log("badges did mount");	
	}

	componentDidUpdate(prevProps) {
	  console.log("badges did update");
	}


	render() {
		console.log("badges render");
		return (
			<div className="badges row">
				{
					this.props.data.map((badgeData, index) => {
						let isUnlocked = (this.props.badgeCount - 1) >= index;
						return (<Badge key={badgeData.id} data={badgeData} unlocked={isUnlocked} />);
					})
				}
			</div>
		);
	}

}

export default Badges;