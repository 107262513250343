import {Component} from 'react';


class MyActivityItem extends Component {
	
	render() {

		return "";
	}
}


export default MyActivityItem;