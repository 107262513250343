import React, { Component } from 'react';

import './display-selector.css';


const DEFAULT_TITLE_MAX_CHAR_COUNT = 160;

const formErrorRequired = "Champ requis";


const FeedDisplayTypes = {
	default: 0,
	imageOnly: 1,
	imageText: 2
};

class DisplaySelector extends Component {


	constructor(props) {
		super(props);

		this.state = {
			displayType: this.props.displayType		
		}
	}

	componentDidMount() {
		this.props.onDisplayModeChange(0);
	}


	render() {
		var content = null;


		if(this.state.displayType === FeedDisplayTypes.default) {
			content = this.renderDefault();
		} else if(this.state.displayType === FeedDisplayTypes.imageOnly) {
			content = this.renderImageOnly();
		} else if(this.state.displayType === FeedDisplayTypes.imageText) {
			content = this.renderImageText();
		}
		return (

			<div className="display-selector">
				{
					content
				}
				{
					this.renderRadio()
				}
			</div>
		)

	}

	onRadioChange(event) {
		console.log(event.target);
		if(event.target.value !== undefined){
			var val = parseInt(event.target.value);
			this.setState({displayType: val});
			this.props.onDisplayModeChange(val);
		}
				
	}

	renderRadioButton(displayType) {
		let selected = displayType === this.state.displayType;

	}

	renderDefault() {
		let title = this.props.title ? this.props.title : "Preview title";
		let body = this.props.body ? this.props.body : "This is some default content";

		return (
			<div className="preview default">
				<img src={this.props.imageSrc} ></img>
				<div className="preview-content">
					<div className="preview-title">{title}</div>
					<div className="preview-text default">{body}</div>
				</div>
			</div>);
	}

	renderImageOnly() {
return (
			<div className="preview image-only">
				<img src={this.props.imageSrc} ></img>
			</div>);
	}

	renderImageText() {
		let title = this.props.title ? this.props.title : "Preview title";


		return (
			<div className="preview image-text">
				<img src={this.props.imageSrc} ></img>
				<div class="preview-text image-text">{title}</div>
			</div>);
	}


	renderRadio() {

		let defaultSelected = this.state.displayType === FeedDisplayTypes.default ? 'checked' : '';
		let imagesOnlySelected = this.state.displayType === FeedDisplayTypes.imageOnly ? 'checked' : ''
		let imageTextSelected = this.state.displayType === FeedDisplayTypes.imageText ? 'checked' : ''

		return (
		<div className="radio-display-selector" onChange={this.onRadioChange.bind(this)}>
			<div>
			  <input type="radio" id="default" name="display-selector-radio" defaultChecked={ defaultSelected } value={FeedDisplayTypes.default} ></input>
			  <label htmlFor="default">{this.props.dataLoad.languageManager.get(479)}</label>
			</div>
			  <div>
			  <input type="radio" id="imageOnly" name="display-selector-radio" value={FeedDisplayTypes.imageOnly} defaultChecked={imagesOnlySelected} ></input>
			  <label htmlFor="imageOnly">{this.props.dataLoad.languageManager.get(480)}</label>
			</div>
			<div>
			  <input type="radio" id="imageText" name="display-selector-radio" value={FeedDisplayTypes.imageText} defaultChecked={imageTextSelected}></input>
			  <label htmlFor="imageText">{this.props.dataLoad.languageManager.get(481)}</label>
			</div>
		</div>
		);
	}


}



export default DisplaySelector;