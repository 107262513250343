import React, { Component } from 'react';
import "./tree-select-item.css";


import Checkbox from './checkbox';


class TreeSelectItem extends Component {

	constructor(props) {
		super(props);
		this.level = this.props.level;
		this.item = this.props.item;

		this.isSelected = this.props.isSelected;
		this.isOpened = this.props.isOpened;
		this.isVisible = this.props.isVisible;
    this.isSelectable = this.props.isSelectable;
    this.isPrimary = this.props.isPrimary;
	}

  componentDidMount = () => {

  }



  onToggleSelection = (event) => {
  	event.stopPropagation();
  	if(this.props.isSelected === true) {
  		this.props.onItemDeselected(this.props.item);
  	} else {
  		this.props.onItemSelected(this.props.item);
  	}
  }

  onToggleChildrenVisibility = () => {
  	if(this.props.isOpened === true) {
  		this.props.onItemClosed(this.props.item);
  	} else {
  		this.props.onItemOpened(this.props.item);
  	}
  }


  renderChildren = () => {
  	if(this.props.item.children.length > 0) {
  		return (this.props.item.children.map((child) => {
            if(this.props.treeManager.isItemRendered(child) === false) {
              return null;
            }
  					return this.renderChild(child);
  				}));
  	} else {
  		return null;
  	}
  }

  renderChild = (child) => {
    let level = this.props.isVisible ? this.props.level + 1 : this.props.level;
  	return (<TreeSelectItem key={child.id} treeManager={this.props.treeManager} level={level} item={child} isPrimary={this.props.treeManager.isPrimaryItem(child)} isOpened={this.props.treeManager.isItemOpened(child)} isSelected={this.props.treeManager.isItemSelected(child)} isSelectable={this.props.treeManager.isItemSelectable(child)} isVisible={this.isChildVisible(child)} onItemOpened={this.props.onItemOpened} onItemClosed={this.props.onItemClosed} onItemSelected={this.props.onItemSelected} onItemDeselected={this.props.onItemDeselected} />);
  }

  isChildVisible = (child) => {
    return this.props.isOpened && this.props.treeManager.isItemVisible(child);
  }


  getItemClass = () => {
  		var itemClass =  "tree-select-item";
  		if(this.props.isVisible === false) {
  			itemClass += " hidden";
  		}
      if(this.props.isPrimary) {
        itemClass += " primary";
      }
  		return itemClass;
  }


  getItemStyle = () => {
    var margin = 0.25*this.props.level;
    let height = 'auto';
    if(this.props.item.children.length == 0) {
      margin += 1.3;
    }
    if(this.props.item.isVisible === false) {
      height = 0;
    }
  	return {marginLeft: margin+"em", height: height};
  }




  renderItemTitle = () => {
  		return (<div className="title">{this.props.item.name}</div>);
  }



  getVisibilityControlClassName = () => {
  	if(this.props.item.children.length > 0) {
  		if(this.props.isOpened) {
  			return "fas fa-minus fa-fw";
  		} else {
  			return "fas fa-plus fa-fw";
  		}
  	} else {
      return "";
    }
  }

  renderChildrenVisibilityControl = () => {
  		return (<div className="visibility-control"><i className={this.getVisibilityControlClassName()} /></div>);
  }



  renderSelectionControl = () => {
    if(this.isSelectable === false) {
        return null;
    }
 		return (<div className="selection-control"><Checkbox selected={this.props.isSelected} onChange={this.onToggleSelection} /></div>);
  }


  render = () => {
  		return (
  		<React.Fragment>
	  		<div className={this.getItemClass()} style={this.getItemStyle()} onClick={this.onToggleChildrenVisibility}>
	  			{this.renderChildrenVisibilityControl()}
	  			{this.renderItemTitle()}
	  			{this.renderSelectionControl()}
	  		</div>
	  		{this.renderChildren()}
  		</React.Fragment>
  		);
  }
  
}

export default TreeSelectItem;
