import React, {Component} from 'react';
import './user-overlay-content.css';
import 'moment';
import TextFormatter from '../../../logic/text-formatter';


class UserOverlayContent extends Component {

	getPictureUrl = () => {
		return this.props.user.pictureUrl;
	}

	renderNetworks = () => {
		return (<div className="networks">
			{this.props.user.socialAccounts.filter((account) => { return account.linkable === true && account.linked === true}).map((account) => {
				let colorStyle = {color: account.feedTypeColor}
				return (<div className="network"><i className={account.feedTypeFontAwesome} style={colorStyle} /></div>);

			}) }

		</div>);
	}

	getLastBadge =  () => {
		return this.props.dataLoad.userGameLevelData[this.props.user.badgeCount-1];
	}

	renderLastBadge = () => {
		if(this.props.user.badgeCount > 0) {
			let badge = this.getLastBadge();
			return <div className="last-badge"><img src={process.env.REACT_APP_BASE_URL+badge.pictureUrlActivated} /><span className="badge-name">{badge.nameActivated}</span></div>
		}

		return null;
	}

	renderStats = () => {
		return (
			<div className="stats">
			{/*<div className="points">{this.props.user.gamePoints+" Points"}</div>*/}
			<div className="stat-grid">
				<div className="stat">
					<div className="label">{this.props.dataLoad.languageManager.get(272)}</div>
					<div className="value">{this.props.user.gamePoints}</div>
				</div>
				<div className="stat">
					<div className="label">{this.props.dataLoad.languageManager.get(273)}</div>
					<div className="value">{this.props.user.gameRank}</div>
				</div>
			</div>
			</div>
		);
	}

	render() {
		return (
			<div className="user-container">
				<div className="user-wrapper">
				<div className="user-content">
					<img alt="avatar" src={process.env.REACT_APP_BASE_URL+this.getPictureUrl()} />
					<div className="username">{this.props.user.name}</div>
					<div className="member-since"><i className="fas fa-user-graduate"></i> {this.props.dataLoad.languageManager.get(267)} : {TextFormatter.formatUserCreationDate(this.props.user)}</div>
					{this.renderStats()}
					{this.renderLastBadge()}
					<div className="status">{this.props.user.description}</div>
					{this.renderNetworks()}
				</div>
				</div>
			</div>
			
		);
	}
}



export default UserOverlayContent;