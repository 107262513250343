import React, { Component } from 'react';
import "./edit-group-content.css";

import EditGroupItem from './edit-group-item';
import EditGroupMasterItem from './edit-group-master-item';


import GroupItemsManager from '../../logic/group-items-manager';

import TextFormatter from '../../logic/text-formatter';

class EditGroupContent extends Component {

	constructor(props) {
		super(props);



		

	   let items = this.prepareItems();
	   let masterItem = this.getMaster(items);
	   let isSlide = masterItem.isSlide;

	   let itemsManager = new GroupItemsManager({dataLoad: this.props.dataLoad, group: items, autoSlide: false});

		this.state = {
			items: items,
			master: masterItem,
			itemsManager: itemsManager,
			autoSlide: isSlide,
		};
	}

	componentDidMount = ()  =>{
		
	}


	getCounts = () => {

		var likeCount = 0;
		var shareCount = 0;
		var viewCount = 0;

		this.state.items.forEach((item) => {
			likeCount += item.likeCounter;
			shareCount += item.shareCounter;
			viewCount += item.viewCounter;
		});	



		return {like: likeCount, share: shareCount, view: viewCount};


	}


	prepareItems = () => {
		let items = this.props.editManager.selectedItems;
		let itemIds = items.map((item) => {
			return item.id;
		})
		let masterItems = items.filter((item) => {
			 return item.isCombined && item.parentId === 0;
		});

		if(masterItems.length >  0) {
			let masterItem = masterItems[0];

			console.log("FOUND MASTER ITEM");
			console.log(masterItem);
			items = items.concat(masterItem.children.filter((child) => { return !itemIds.includes(child.id) }));
		} else {
			let firstItem = items[0];
			if(firstItem.parentId) {
				let foundParents = this.props.dataLoad.originalFeedItems.filter((item) => {
					return item.id === firstItem.parentId;
				});

				if(foundParents.length > 0) {
					let foundParent = foundParents[0];
					items.push(foundParent);
					items = items.concat(foundParent.children.filter((child) => { return !itemIds.includes(child.id) }));
				}
			}
		}

		console.log(items);
		return items;

	}


	changeSelection = (item) => {
		console.log("CHanging selection "+item.id);
		this.state.itemsManager.changeSelection(item);
	}


	getChildren = () => {
		let masterId = this.state.master.id;
		console.log(this.state.items);
		let children = this.state.items.filter((item) => {
			return item.id !== masterId;
		});
		console.log(masterId);
		console.log(children);

		return children;
	}

	getMaster = (items) => {
		let masterItems = items.filter((item) => {
			 return item.isCombined && item.parentId === 0;
		});

		if(masterItems.length >  0) {
			return masterItems[0];
		} else {
			return items[0];
		}

	}

	stopPropagation = (event) => {
		event.stopPropagation();
	}


	cancel  = (event) => {
		this.props.cancel();
	}

	onSelectMaster = (item) => {
		this.setState({master: item});
	}

	validate = () => {
		//save group
	}


	toggleAutoSlide = () => {
		this.state.itemsManager.setAutoSlide(!this.state.autoslide);
		this.setState({autoSlide: !this.state.autoslide});
	}

	renderChildren = () =>  {
		let children = this.getChildren();
		console.log("children");
		console.log(children);
		return children.map((child) => {
			return <EditGroupItem key={child.id} item={child} onSelectMaster={this.onSelectMaster} isMaster={false} dataLoad={this.props.dataLoad} selected={this.state.itemsManager.isSelected(child)} changeSelection={this.changeSelection}/>;
		});
	}



	renderGlobalCounters = () => {
		let counts = this.getCounts();

		var likeCount = null;
		var shareCount = null;
		var viewCount = null;

		if(counts.like > 0) {
			likeCount = (<span className="count"><i className="fa fa-heart"></i>{TextFormatter.formatNumber(counts.like)}</span>);
		}
		if(counts.share > 0) {
			shareCount = (<span className="count"><i className="fa fa-share-alt"></i>{TextFormatter.formatNumber(counts.share)}</span>);
		}
		if(counts.view > 0) {
			viewCount = (<span className="count"><i className="fa fa-eye"></i>{TextFormatter.formatNumber(counts.view)}</span>);
		}


		return (<React.Fragment>{likeCount}{shareCount}{viewCount}</React.Fragment>);
	}



	validate = () => {
		this.state.master.isSlide = this.state.autoSlide;
		this.state.itemsManager.saveSelectionWithMaster(this.state.master).then((response) => {
			this.props.refresh();
			this.props.cancel();
		}).catch((error) => {
				this.props.refresh();
			this.props.cancel();
			alert(error);
		});
	}

	render() {
		return (<div className="edit-group" onClick={this.stopPropagation}>

					<div className="header"><div className="title">{this.props.dataLoad.languageManager.get(49)}</div><div className="counters">{this.renderGlobalCounters()}</div><div className="control"><div className="autoslide"><label>Autoslide </label><input type='checkbox' defaultChecked={this.state.autoSlide} onChange={this.toggleAutoSlide}/></div></div></div>
					<hr />
					<div className="body">
						<div className="master"> 
							<EditGroupMasterItem item={this.state.master} dataLoad={this.props.dataLoad} />
						</div>
						<div className="children">
							{this.renderChildren()}
						</div>
						<div className="controls">
							<button className="btn btn-danger cancel" onClick={this.cancel}>{this.props.dataLoad.languageManager.get(93)}</button><button className="btn btn-success validate" onClick={this.validate}>{this.props.dataLoad.languageManager.get(92)}</button>
						</div>
					</div>
		</div>);
	}


}



export default EditGroupContent;