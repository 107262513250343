import React, { Component } from 'react';
import "./component-overlay.css";



class ComponentOverlay extends Component {


  exit = () => {
    this.props.exit();
  }


  render() {

    var overlayClass = "component-overlay";
    if(this.props.specialClass !== undefined) {
      overlayClass += " "+this.props.specialClass;
    }

    if(this.props.active === true) {
        overlayClass += " active";
    }

    if(!this.props.active) {
      return null;
    }

  	return(
  		<div className={overlayClass} onClick={this.exit}>
          <div className="content">
             {this.props.children}
          </div>
  		</div>
  	);
  }
}

export default ComponentOverlay;
