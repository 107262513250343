

class CampaignItemsManager {


	constructor(props) {

		this.props = props;

		this.campaign = this.props.campaign;
		this.itemsToAdd = [];
		this.itemsToRemove = [];
	}


	registerForAdd = (item) =>  {
		if(!this.itemsToAdd.includes(item)) {
			console.log("registerForAdd");
			this.unregisterForRemove(item);
			this.itemsToAdd.push(item);
		}
	}


	unregisterForAdd = (item) => {
		if(this.itemsToAdd.includes(item)) {
				console.log("unregisterForAdd");
			this.itemsToAdd = this.itemsToAdd.filter((filterItem) => {
				return item.id !== filterItem.id;
			});
		}
	}


	registerForRemove = (item) => {
		if(!this.itemsToRemove.includes(item)) {
			console.log("registerForRemove");
			this.unregisterForAdd(item);
			this.itemsToRemove.push(item);
		}
	}

	unregisterForRemove = (item) => {
		if(this.itemsToRemove.includes(item)) {
			console.log("unregisterForRemove");
			this.itemsToRemove = this.itemsToRemove.filter((filterItem) => {
				return item.id !== filterItem.id;
			});
		}
	}


	validateItems = () => {
		//For each list prepare the promises and start them synchronously

		let addPromises = this.itemsToAdd.map((item, index) => {
			return this.props.dataLoad.addItemToCampaign(item, this.campaign);
		});

		let removePromises = this.itemsToRemove.map((item, index) => {
			return this.props.dataLoad.removeItemFromCampaign(item, this.campaign);
		});

		let itemPromises = addPromises.concat(removePromises);

		return Promise.all(itemPromises).then(() => {
			this.itemsToAdd = [];
			this.itemsToRemove = [];
		});
	}

}


export default CampaignItemsManager;