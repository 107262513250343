

class Commons {


	static getSocialCounts = (items) => {
		var likeCount = 0;
		var shareCount = 0;
		var viewCount = 0;

		items.forEach((item) => {
			likeCount += item.likeCounter;
			shareCount += item.shareCounter;
			viewCount += item.viewCounter;
		});	



		return {like: likeCount, share: shareCount, view: viewCount};
	}

}



export default Commons;