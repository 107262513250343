import moment from 'moment';
import fr from 'moment/locale/fr';


let ACCEPTED_PARAMS = {
    "APP_URL": process.env.REACT_APP_BASE_URL,
    "APP_NAME": process.env.REACT_APP_NAME,
    "PRIMARY_GROUP_NAME": ""
};



class LanguageManager {

  constructor(props) {

    this.entries = props.entries;
    this.currentLanguage = props.defaultLanguage ? props.defaultLanguage : 2;
    if(props.appName !== undefined) {
        this.setAppName(props.appName);
    }
    this.setupLocale();
  }

  changeLanguage = (languageId) => {
    this.currentLanguage = languageId;
    this.setupLocale();
  }


  setAppName = (appName) => {
    ACCEPTED_PARAMS.APP_NAME = appName;
  }

  setPrimaryGroupName = (primaryGroupName) => {
    ACCEPTED_PARAMS.PRIMARY_GROUP_NAME = primaryGroupName;
  }

  setupLocale = () => {
    if(this.currentLanguage === 1) {
      moment.updateLocale("fr", fr);
    } else {
      moment.locale("en");
    }
  }

  get = (id, language=this.currentLanguage) => {
    for (var i = 0; i < this.entries.length; i++) {
      if (this.entries[i].id === id) {
        let text = this.getTextFromEntry(this.entries[i], language);
        let formattedText = this.formattedText(text);
        return formattedText;
      }
    }
    return 'n.a.';
  }

  formattedText = (text) => {
      let regex = new RegExp('%[A-Z_]+%', 'g');
      if(regex.test(text)) {
          let uniqueParams = new Set(text.match(regex));
          uniqueParams.forEach((param) => {
              let paramName = param.replace(new RegExp("%", "g"), "");
              let accepted = ACCEPTED_PARAMS.hasOwnProperty(paramName);
              if(accepted) {
                  text = text.replace(new RegExp(param, "g"), ACCEPTED_PARAMS[paramName]);
              }
          });
      }
      return text;
  }


  getTextFromEntry = (entry, language=this.currentLanguage) => {
      switch (language) {
        case 1: return entry.fr;
        case 2: return entry.en;
        case 3: return entry.ind;
        default: console.log("bad language id : "+language); return 'n.a.';
      }
  }

  getByName = (name, language=this.currentLanguage, nameLanguage=this.currentLanguage) => {
      for (var i = 0; i < this.entries.length; i++) {
          let nameText = this.getTextFromEntry(this.entries[i], nameLanguage);
          if(nameText === name) {
              return this.getTextFromEntry(this.entries[i], language);
          }
      }
      return 'n.a.';
  }

}

export default LanguageManager;