import React, { Component } from 'react';
import TextFormatter from '../../logic/text-formatter';

import "./campaign-items.css";

class CampaignItems extends Component {


	onChange = (event) => {
		if(event.target.checked) {
			this.props.onSelect(this.props.data);
		}
	}


	renderDate = () => {
		let start = TextFormatter.formatCampaignDate(this.props.data.dateStart);
		let end = TextFormatter.formatCampaignDate(this.props.data.dateEnd);

		let finalText = this.props.formatCampaignDate(start, end);

		return finalText;
	}

	render() {
		return <div className="campaign content-list row no-gutters">
			<div className="col col-2">
				<div className="selection">
					<input type="checkbox" onChange={this.onChange} checked={this.props.selected} />
				</div>
			</div>
			<div className="col col-6 infos">
				<div className="name">
					{this.props.data.name}
				</div>
				<div className="date">
					{this.renderDate()}
				</div>
			</div>

			<div className="col col-2">
					<div className="owner-logo">
						<img alt="campaign" src={this.props.data.typeLogoURL}></img>
					</div>
			</div>
			<div className="col col-2">
				<div className="bonus">
					{this.props.data.bonusName}
				</div>
			</div>
			
		</div>;
	}


}



export default CampaignItems;