import React, { Component } from 'react';

class Channel extends Component {


	constructor(props) {
		super(props);

		this.channel = props.channel;
		this.channelId = props.channel.id;
		this.dataLoad = props.dataLoad;

		this.state = {
			active: this.props.active,
		}
	}


	toggle = () => {
		this.dataLoad.filtersManager.channelToggle(this.channelId, !this.state.active);
		this.setState({active: this.props.dataLoad.filtersManager.channelFilters.includes(this.props.channel.id)});
		this.props.onToggle();
		this.props.refresh();
	}


	render() {

		let imageStyle = this.channel.imageURL !== "" ? { backgroundImage: `url(${process.env.REACT_APP_BASE_URL+this.channel.imageURL})` } :  {backgroundColor: "white"};
        var channelClass = "channel";

       	if(this.state.active) {
			channelClass += " active";
		}

		return (
			<div className={channelClass} onClick={this.toggle}>
               	<div className="channel-image" style={imageStyle}></div>
                <div className="channel-name">{this.channel.name}</div>
            </div>
		)
	}
}



export default Channel;