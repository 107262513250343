import React, { Component } from 'react';
//import { Button } from 'reactstrap';

class Badge extends Component {


	getPictureUrl = () => {
		if(this.props.unlocked) {
			return this.props.data.pictureUrlActivated;
		} else {
			return this.props.data.pictureUrl;
		}
	}


	getBadgeName = () => {
		if(this.props.unlocked) {
			return this.props.data.nameActivated;
		} else {
			return this.props.data.name;
		}		
	}


	onClick = () => {
		this.props.onBadgeClicked(this.props.data);
	}


	render() {
		return (
			<div className="badge" onClick={this.onClick}>
				<img alt="badge" src={process.env.REACT_APP_BASE_URL+this.getPictureUrl()} />
				<div className="badge-name text-center">{this.getBadgeName()}</div>
				<div className="badge-points text-center">{this.props.data.points}</div>
			</div>
		);
	}

/*	  {
    "id": 15,
    "points": 1,
    "name": "?",
    "pictureUrl": "/ws/download/assetgamelevel/58/image.png",
    "nameActivated": "Newbie",
    "pictureUrlActivated": "/ws/download/assetgamelevel/59/image.png"
  } */

}

export default Badge;