import React, {Component} from 'react';
//import {Button} from 'reactstrap';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


/*"id": 0,
    "feedTypeId": 5,
    "feedTypeName": "Facebook",
    "feedTypeColor": "#3b5998",
    "feedTypeFontAwesome": "fa-facebook",
    "username": "",
    "email": "",
    "picture": "https://graph.facebook.com//picture?width=130&height=130",
    "linked": false,
    "loginUrl": "/oauth/login/?FeedType=5&method=login",
    "linkUrl": "/oauth/login/?userid=2067&FeedType=5&method=link",
    "unlinkUrl": "/oauth/logout/?userid=2067&FeedType=5"*/

class Network extends Component {


	onNetworkChangeLink = (linkUrl) => {
		window.current = {feedTypeReset: this.props.onReloadUser };
		window.open(linkUrl);
	}


	renderNetworkName = () => {
		if(this.props.data.linked === false) {
		return (<div className="network-name">{this.props.data.feedTypeName}</div>);
		}
		return null;
	}

	renderUserInfo = () => {
		if(this.props.data.linked === true) {
			return (
				<div className="user-info"><div className="user mr-3"><div className="username">{this.props.data.username}</div><div className="user-email">{this.props.data.email}</div></div>{this.renderPicture()}</div>
					);
		}
	}

	render() {


		const linkClass = this.props.data.linked === false ? "btn btn-secondary" : "btn btn-danger";
		var linkUrl = this.props.data.linked === false ? this.props.data.linkUrl : this.props.data.unlinkUrl;
		linkUrl += '&bearer='+this.props.bearer;

		const linkText = this.props.data.linked === false ? this.props.localize.get(160) : this.props.localize.get(161);


		let username = "Utilisateur test";
		let email = "test@email.test";

		var networkClass = this.props.even ? "network even" : "network odd";
					let iconStyle  = {backgroundColor: this.props.data.feedTypeColor};

		return (
			<div className={networkClass}>
				<div className="text-left network-info"><div className="network-icon" style={iconStyle}><i className={this.props.data.feedTypeFontAwesome+" fa-fw"} /></div>{this.renderNetworkName()}</div>
				{this.renderUserInfo()}
				<div className="text-right network-connect"><button className={linkClass} onClick={this.onNetworkChangeLink.bind(this, linkUrl)} >{linkText}</button></div>
			</div>
		);
	}
	
	renderPicture = () => {
		if(this.props.data.picture.length > 0) {
			return <img src={this.props.data.picture} />
		}
		return null;
	}
}

export default Network;