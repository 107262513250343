import React, { Component } from 'react';
import './badge-item.css';
//import { Button } from 'reactstrap';

class BadgeItem extends Component {




	badgeClicked = () => {
		this.props.onBadgeClicked(this.props.data);
	}


	render() {
		return (
			<div className="badge-item-container">
				<div className="badge-item-wrapper" onClick={this.badgeClicked}>
				<div className="badge-item-content">
					{this.props.children}
				</div>
				{this.renderFooter()}
				</div>
			</div>
		);
	}


	renderFooter = () => {
		if(this.props.footerText === null) {
			return null;
		}
		return (
			<div className="badge-item-footer">
					<div className="badge-item-footer-text text-center">{this.props.footerText}</div>
				</div>
		);
	}

/*	  {
    "id": 15,
    "points": 1,
    "name": "?",
    "pictureUrl": "/ws/download/assetgamelevel/58/image.png",
    "nameActivated": "Newbie",
    "pictureUrlActivated": "/ws/download/assetgamelevel/59/image.png"
  } */

}

export default BadgeItem;