

class GroupItemsManager {


	constructor(props) {

		this.props = props;

		this.group = this.props.group;
		this.selectedItems = this.group;

		this.removedItems = [];


		this.autoSlide = props.autoSlide;
	}


	changeSelection = (item) => {
		if(this.selectedItems.includes(item)) {

			console.log(this.selectedItems);

			//Remove
			this.selectedItems = this.selectedItems.filter((selectedItem) => {
				return item.id !== selectedItem.id;
			});

			console.log("REMOVE !"+item.id);
			console.log(this.selectedItems);
		} else {
			this.selectedItems.push(item);
		}
	}

	isSelected = (item) => {
		return this.selectedItems.includes(item);
	}



	setAutoSlide = (autoSlide) => {
		this.autoSlide = autoSlide;
	}



	saveSelectionWithMaster = (master) => {
		let updatedItems = this.selectedItems.map((item) => {
			if(item.id === master.id) {
				item.parentId = 0;
			} else {
				item.parentId = master.id;
			}
			return item;
		});

		console.log(updatedItems);

		let removedItems = this.group.filter((item) => {
			return !this.selectedItems.includes(item);
		});

		removedItems.forEach((item) => {
			item.parentId = 0;
		});

		let savedItems = updatedItems.concat(removedItems);

		console.log(savedItems);

		let promises = savedItems.map((item) => {
			return this.props.dataLoad.updateItem(item);
		});


		return Promise.all(promises);
	}

}


export default GroupItemsManager;