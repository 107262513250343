import React, { Component } from 'react';
import ProfileCard from './profile-card';

import posed from 'react-pose';
import Badges from './badges';

import MyUser from './my-user';
import GamificationRules from './gamification-rules';
import Networks from './networks';
import Summary from './summary';
import Leaderboard from './leaderboard';

import MyActivity from './my-activity';
import ActivityFeed from './activity-feed';

import './my-profile.css';

import Overlay from '../common/overlay';

import Overview from './overview';
import Coverflow from '../common/coverflow';
import Badge from './badge';
import UserInfos from './user-infos';

import BadgeOverlayContent from './pages/badge-overlay-content';
import RewardOverlayContent from './pages/reward-overlay-content';
import UserOverlayContent from './pages/user-overlay-content';

import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import TabList from './tab-list';
import ItemOverlay from '../wall/item-overlay';
import ShareThanksContent from '../wall/share-thanks-content';

import TextFormatter from '../../logic/text-formatter';


import CampaignContent from '../wall/campaign-content';

class MyProfile extends Component {



	constructor(props) {
		super(props);


		this.localize = this.props.dataLoad.languageManager;

		this.userData = this.props.dataLoad.userData;
		this.userGroupData = null;
		this.userStats = null;
		this.socialAccountsData = this.props.dataLoad.userData.socialAccounts;
		this.userGameLevelData = null;
		this.leaderboardData = null;
		this.userActivityData = null;
		this.userActivityFeedData = null;
		this.inviteAFriendItem = null;


		this.shareItem = null;
		this.shareFeed = null;

		this.state = {
			errors: [],
			isLoaded: false,
			localize: null,
			userData: undefined,
			groupsData: undefined,
			badgesTitle: "",
			gamelevelData: undefined,
			networksTitle: "",
			summaryTitle: "",
			leaderboardData: undefined,
			leaderboardTitle: "",
			myActivityData: undefined,
			myActivityTitle: "",
			activityFeedData: undefined,
			activityFeedTitle: "",
			groupOverlayOpened: false,
			overlayContent: null,
			selectedItem: null,
		}


	}

	getUserData() {
		return new Promise((resolve,  reject) => {
			this.tempData["user"] = this.props.dataLoad.userData;
			resolve(this.props.dataLoad.userData);
		});
	}

	handleError(error) {
		console.log("Error "+error);
		this.setState({errors: this.state.errors.concat(error)});
	}

	getUserGroups = () => {
		return this.props.dataLoad.getUserGroups().then((userGroupData) => {
			this.userGroupData = userGroupData;
		});
	}

	getUserGameLevel = () => {
		return this.props.dataLoad.getUserGameLevel().then((userGameLevelData) => {
			this.userGameLevelData = userGameLevelData;
			return userGameLevelData;
		});
	}

	getLeaderboard = (from, to) => {
		return this.props.dataLoad.getLeaderboard(from, to).then((leaderboardData) => {
			this.leaderboardData = leaderboardData;
			this.setState({leaderboardData: leaderboardData});
			return leaderboardData;
		});
	}

	getUserActivity = () => {
		return this.props.dataLoad.getUserActivity().then((userActivityData) => {
			this.userActivityData = userActivityData;
		});
	}

	getUserActivityFeed = () => {
		return this.props.dataLoad.getUserActivityFeed().then((userActivityFeedData) => {
			this.userActivityFeedData = userActivityFeedData;
		});
	}


	getInviteAFriendItem = () => {
		return this.props.dataLoad.getFeedItem(process.env.REACT_APP_INVITE_ID).then((inviteAFriendItem) => {
			this.inviteAFriendItem = inviteAFriendItem;
		});		
	}


	getUserStats = () => {
		return this.props.dataLoad.getCurrentUserStats().then((stats) => {
			this.userStat = stats;
		});
	}

	componentDidMount() {

		var promises = [];

		promises.push(this.getUserGroups());
		promises.push(this.getUserGameLevel());
		if(process.env.REACT_APP_INVITE_ID !== undefined) {
			console.log(process.env.REACT_APP_INVITE_ID);
			promises.push(this.getInviteAFriendItem());
		}
		promises.push(this.getUserStats());

		let dateFrom = new Date(2016, 0, 1).getTime();
    	let dateTo = Date.now();
		//let leaderboardUrl = '/apireporting/leaderboard/?topx=20&datefrom='+dateFrom+'&dateto='+dateTo;

		//promises.push(this.getLeaderboard(dateFrom, dateTo));
		//promises.push(this.getUserActivity());
		//promises.push(this.getUserActivityFeed());

		Promise.all(promises).then((response) => {
			this.setState({isLoaded: true, badgesTitle: this.localize.get(111), networksTitle: this.localize.get(112), summaryTitle: this.localize.get(115), "leaderboardTitle": this.localize.get(122), myActivityTitle: this.localize.get(124), activityFeedTitle: this.localize.get(125)});
		});
		/*

		

		this.getUserData().then(userData => {
			this.getData("/dictionary").then(langageData => {
				this.localize = new Localize(userData.language.id, langageData.dictionaryEntries);
				this.setState({ localize: this.localize, badgesTitle: this.localize.get(111), networksTitle: this.localize.get(112), summaryTitle: this.localize.get(115), "leaderboardTitle": this.localize.get(122), myActivityTitle: this.localize.get(124), activityFeedTitle: this.localize.get(125) });
				console.log("LOCALIZE");


				this.getData("/usergroup").then(groupData => {
					this.setState({groupsData: groupData, userCardPose: "loaded" });
				});

				this.getData("/socialaccount/"+userData.id).then(socialaccountData => {
					this.tempData["socialaccount"] = socialaccountData;
					this.setState({
						networksCardPose: "loaded"
					});
				});

				this.getData("/gamelevel").then(data => {
					this.tempData["gamelevel"] = data;
					this.setState({
						badgesCardPose: "loaded"
					});
				});



    			let dateFrom = new Date(2016, 0, 1).getTime();
    			let dateTo = Date.now();
				let leaderboardUrl = '/apireporting/leaderboard/?topx=20&datefrom='+dateFrom+'&dateto='+dateTo;

				this.getData(leaderboardUrl).then(leaderboardData => {
					this.tempData["leaderboard"] = leaderboardData;
					this.setState({
						leaderboardCardPose: "loaded"
					});
				});


				this.getData("/activity/currentuser/?top=100000").then(activityData => {
					this.setState({myActivityData : activityData});
				});

				this.getData("/activity/any/?top=25").then(activityFeedData => {
					this.setState({activityFeedData : activityFeedData});
				});

			});

			
		});
		
	*/
		

		/*this.getData("/user/networks")
			.then(data => {
				this.tempData["networks"] = data;
				this.setState({
					networksCardPose: "loaded"
			});
		});*/
	}


	onBadgeClicked = (badge) => {
		if(badge.relatedFeedItemId !== undefined && badge.relatedFeedItemId > 0) {
			this.props.dataLoad.fetch("feedItem/"+badge.relatedFeedItemId).then((item) => {
				this.openBadgeOverlay(badge, item.body, item);
			});
		} else {
			this.openBadgeOverlay(badge, this.props.dataLoad.languageManager.get(226));
		}
	}

	onRewardClicked = (reward) => {
		this.openRewardOverlay(reward);
	}

	onCampaignClick = (item) => {
		if(item.campaign !== undefined) {
			this.openCampaignOverlay(item);
		} else {
			this.openCampaignOverlay({campaign: item});
		}
	}


	renderTabListItemFallbackContent = (item) => {
		//const feedTypeId = item.parentFeed.parentFeedType.id;
		const iconClass = item.parentFeed.parentFeedType.fontAwesome;
		const backgroundColor = item.parentFeed.parentFeedType.color;
		//var backgroundColor = "white";

		//const feed = this.props.dataLoad.reposFeedTypes.find((feed) => {return feed.feedTypeId === feedTypeId});
		/*if(feed !== undefined) {
			backgroundColor = feed.color;
		}*/ 
		return (<div className="feed" style={{backgroundColor: backgroundColor}}><i className={iconClass} /></div>);
	}


	openBadgeOverlay = (badge, description, feedItem) => {
		this.setState({overlayContent: <BadgeOverlayContent dataLoad={this.props.dataLoad} badge={badge} description={description} unlocked={this.userData.gamePoints >= badge.points} feedItem={feedItem} exit={this.closeOverlay} doShare={this.doItemOverlayShare} />})
	}

	openRewardOverlay = (reward) => {
		this.setState({overlayContent: <RewardOverlayContent dataLoad={this.props.dataLoad} reward={reward} exit={this.closeOverlay} doShare={this.doItemOverlayShare} />})
	}

	openCampaignOverlay = (item) => {
		this.setState({overlayContent: <CampaignContent item={item} dataLoad={this.props.dataLoad} />})
	}


	openUserOverlay = (user) => {
		this.setState({overlayContent: <UserOverlayContent dataLoad={this.props.dataLoad} user={user} />})
	}

	closeOverlay = () => {
		this.setState({overlayContent: null});
	}

	renderBadgeCoverflowItem = (item) => {
		return <Badge data={item} key={item.id} unlocked={ this.userData.gamePoints >= item.points } onBadgeClicked={this.onBadgeClicked} />
	} 

	renderLeaderboardCoverflowItem = (item) => {
		return <UserInfos data={item} key={item.id} userClicked={this.openUserOverlay} />
	}

	renderBadgesCoverflow =() => {
		///if(this.props.dataLoad.globalSettings.gamification === true) {

			let selectedIndex = this.userData.badgeCount > 0 ? (this.userData.badgeCount - 1) : 0;
			return (
				<React.Fragment>
					{
						//this.renderViewModeControls(this.props.dataLoad.languageManager.get(111), "badges-controls", '/profile/badges')
					}
					<div className="view-mode-controls">
						<div className="title">{this.props.dataLoad.languageManager.get(111)}</div>
					</div>	
					<Coverflow name="badges" items={this.userGameLevelData} index={selectedIndex} pageSize={3} renderCoverflowItem={this.renderBadgeCoverflowItem} async={false} />
				</React.Fragment>

			);
		//}

		return null;
	}

	getLeaderboardSelectedItemIndex = (leaderboard) => {
		let foundIndex = leaderboard.findIndex((user) => { return user.id === this.props.dataLoad.userData.id });
		if(foundIndex > -1) {
			return foundIndex;
		} else {
			return 0;
		}

	}

	renderLeaderboardCoverflow = () => {

		let dateFrom = new Date(2016, 0, 1).getTime();
    	let dateTo = Date.now();
		return (
			<React.Fragment>
			{ this.renderViewModeControls(this.props.dataLoad.languageManager.get(122), "leaderboard-controls", "/profile/leaderboard") }
			<Coverflow name="leaderboard" itemFetcher={{fetchItems: this.getLeaderboard.bind(this, dateFrom, dateTo)}} itemSelector={{getSelectedItemIndex: this.getLeaderboardSelectedItemIndex}} index={0} pageSize={3} renderCoverflowItem={this.renderLeaderboardCoverflowItem} async={false} />
			</React.Fragment>		
		);
	}



	renderChannelsCoverflow = () => {
		return (<React.Fragment>
			{
				this.renderViewModeControls(this.props.dataLoad.languageManager.get(215), "channel-controls", "/profile/channels")
			}
			<Coverflow name="channels" items={this.props.dataLoad.channels.filter((channel) => { return channel.isTelex === false; })} index={0} pageSize={3} renderCoverflowItem={this.renderChannelCoverflowItem} async={false} />
			</React.Fragment>);
	}


	renderChannelCoverflowItem = (channel) => {
		let linkPath = "channel/"+channel.slug;
		return (
		
				<div className="channel" onClick={this.channelClicked.bind(this, channel)} >
							<img src={process.env.REACT_APP_BASE_URL+channel.imageURL} />
							<div className="channel-name">{channel.name}</div>
				</div>
		);
	}	

	channelClicked = (channel) => {
		window.location = "/app/channel/"+channel.slug;
	}


	renderChannelsGrid = () => {
		return (
			<React.Fragment>
			{
				//this.renderViewModeControls(this.props.dataLoad.languageManager.get(215), "channel-controls", "/profile/channels")
			}
			<div className="channels">
				{
					this.props.dataLoad.channels.filter((channel) => { return channel.isTelex === false}).map((channel) => {
						return (<div className="channel">
							<img src={process.env.REACT_APP_BASE_URL+channel.imageURL} />
							<div className="channel-name">{channel.typeName.slice(0, 30)}</div>
						</div>);
					})
				}

			</div>
			</React.Fragment>
		);
	}


	renderCampaignsCoverflow = () => {
		if(this.props.dataLoad.globalSettings.gamification == true) {
			return (
				<React.Fragment>
				<div className="view-mode-controls">
					<div className="title">{this.props.dataLoad.languageManager.get(121)}</div>
				</div>	
				<Coverflow name="campaigns" itemFetcher={{fetchItems: this.props.dataLoad.getCampaignList}} index={0} pageSize={3} renderCoverflowItem={this.renderCampaignCoverflowItem} async={false} />
				</React.Fragment>
			);	
		}	
	}

	renderRewardsCoverflow = () => {
		if(this.props.dataLoad.globalSettings.gamification == true) {
			return (
				<React.Fragment>
				<div className="view-mode-controls">
					<div className="title">{this.props.dataLoad.languageManager.get(251)}</div>
				</div>	
				<Coverflow name="rewards" itemFetcher={{fetchItems: this.props.dataLoad.getRewards}} itemSelector={ {getSelectedItemIndex: this.rewardSelector} } index={0} pageSize={3} renderCoverflowItem={this.renderRewardCoverflowItem} async={false} />
				</React.Fragment>
			);		
		}
	}


	rewardSelector = (rewards) => {
		let lastRewardIndex  = 0;
		let points = this.props.dataLoad.userData.gamePoints;

		let unlockedRewards = rewards.filter((reward) => {
			return reward.rewardPoints <= points;
		});

		let foundRewardIndex  = unlockedRewards.length - 1;
		if(foundRewardIndex > lastRewardIndex) {
			lastRewardIndex = foundRewardIndex;
		}

		return lastRewardIndex;
	}


	renderCampaignCoverflowItem = (campaign) => {
		return (<div className="campaign" key={campaign.id} onClick={this.onCampaignClick.bind(this, campaign)}>
			<img src={process.env.REACT_APP_BASE_URL+campaign.typeLogoURL} />
			<div className="name">{campaign.name.slice(0, 30)}</div>
		</div>);
	}

	renderRewardCoverflowItem = (reward) => {
		let unlocked = this.props.dataLoad.userData.gamePoints >= reward.rewardPoints;
		let rewardClass = "";
		if(!unlocked) {
			rewardClass = "locked";
		}

		return (<div className={"reward "+rewardClass} key={reward.id} onClick={this.onRewardClicked.bind(this, reward)}>
				<div className="ratio">
					<div className="content">
						<img src={process.env.REACT_APP_BASE_URL+reward.imageURL}></img>
						{this.renderRewardPoints(reward)}
					</div>
				</div>
		</div>);
	}

	renderRewardPoints = (reward) => {
		if(reward.rewardPoints == 0) {
			return null;
		}
		return (<div className="title">{reward.rewardPoints.toLocaleString()}</div>);
	}


	renderViewModeControls = (title, className, linkUrl, grid=true) => {

		//let className = "vew-mode-controls "+className;


		let iconClass = (grid === true ? 'fas fa-th-large inactive' : 'fas fa-th-list inactive');

		return (
		<div className={"view-mode-controls "+className} >
			<div className="title">{title}</div>
			<div className="controls">
				<i className="fas fa-exchange-alt" />
				<Link to={linkUrl}><i className={iconClass} /></Link>
			</div>
		</div>	
		);
	}


	fetchTopPosts = () => {
		return this.props.dataLoad.getTopPosts();
	}

	fetchMyActivity = () => {
		return this.props.dataLoad.getUserActivity().then((response) => { return response.map((item) => { return item.FeedItem })});
	}

	fetchMyGroup = () => {
		return this.props.dataLoad.getGroupPosts().then((response) => { return response.map((item) => { return item; })});//.then((response) => { return response.map((item) => { return item.FeedItem })});
	}

	fetchCampaigns = () => {
		return this.props.dataLoad.getCampaignPosts();//.then((response) => { return response.map((item) => { return item.FeedItem })});
	}

	renderItemsTabList = () => {
		let categories = [
			{"title":this.props.dataLoad.languageManager.get(268), "fetchItems": this.fetchTopPosts}, 
			{"title":this.props.dataLoad.languageManager.get(124), "fetchItems": this.fetchMyActivity}, 
			{"title":this.props.dataLoad.languageManager.get(234), "fetchItems": this.fetchMyGroup}, 
			{"title":this.props.dataLoad.languageManager.get(216), "fetchItems": this.fetchCampaigns}];
		//let items = this.props.dataLoad.originalFeedItems.slice(0, 15);

		return (<TabList categories={categories} categorySelected={this.itemTabListCategorySelected} itemClicked={this.itemTabListItemClicked} fullListPath="/feed" fallbackImageUrl={this.props.dataLoad.globalSettings.urlLogoLogIn} items={[]} renderFallbackContent={this.renderTabListItemFallbackContent} emptyText={this.props.dataLoad.languageManager.get(233)} />);
	}


	itemTabListCategorySelected = (category) => {
		console.log("Selected category "+category);
	}

	itemTabListItemClicked = (item) => {
		console.log("Selected item "+item);
		this.setState({"selectedItem": item});
	}

	onCloseItemOverlay = () => {
		this.setState({"selectedItem": null});
	}

	renderItemOverlay = () => {
		return <ItemOverlay item={this.state.selectedItem} onClose={this.onCloseItemOverlay} dataLoad={this.props.dataLoad} doShare={this.doItemOverlayShare} toggleEdit={this.toggleEditWithItem} onCampaignClick={this.onCampaignClick.bind(this, this.state.selectedItem)} repostCallback={this.repostCallback} onShareClicked={this.shareClicked} onCancelShare={this.onCancelShare} footer={null} editable={false} />
	}

	renderItemOverlayFooter = (item) => {
	    return (<div className="share-footer">{this.renderDate()}<div className="share" onClick={this.shareClicked}><i className="fa fa-share-alt" /></div></div>);
	}

	onCancelShare = () => {
	    //nothing to do;
	}

	shareClicked = (item, feed, footer) => {
		//Nothing to do
		//this.doShare(item, feed)
	}

   doItemOverlayShare = (url, item, feed) => {
   	/*console.log("Sharing");
   	console.log(feed);
    let iframeUrl = process.env.REACT_APP_BASE_URL+'repost?feedItemId='+item.id+'&feedTypeId='+feed.feedTypeId+'&activityTypeId='+feed.actionType+'&bearer='+this.props.dataLoad.apiBearer;
    
    this.shareItem = item;
    this.shareFeed = feed
    window.wall = { currentModal: { repostCallback: this.repostCallback }};
    this.setState({selectedItem: null, overlayContent : this.renderIframe(iframeUrl)});*/



      this.shareItem = item;
      this.shareFeed = feed;
      switch (feed.feedTypeId) {
          case 4:
              if(feed.actionType == 2) {
                window.shareDialog.likeTwitter(item.id);
              } else if(feed.actionType == 3) {
                window.shareDialog.openTwitter(item.id);
              } else if(feed.actionType == 4) {
                window.shareDialog.retweetTwitter(item.id);
              }
              break;
          case 5:
              window.shareDialog.openFacebook(item.id);
              break;

          case 6:
              window.shareDialog.openLinkedIn(item.id);
              break;

          case 11:
            window.shareDialog.openEmail(item.id);
            break;

          case 12:
            window.shareDialog.openWhatsApp(item.id);
            break;

          case 13:
            window.shareDialog.openFacebookMessenger(item.id);
            break;

      }

  }


  doShare = (feed) =>  {

  	/*Share Facebook: shareDialog.openFacebook(<%=PostId%>);
Share Twitter: shareDialog.openTwitter(<%=PostId%>);
Like Twitter: shareDialog.likeTwitter(<%=PostId%>);
Retweet Twitter: shareDialog.retweetTwitter(<%=PostId%>);
Share LinkedIn: shareDialog.openLinkedIn(<%=PostId%>);
Share Email: shareDialog.openEmail(<%=PostId%>);
Share WhatsApp: shareDialog.openWhatsApp(<%=PostId%>);
Share Facebook Messenger: shareDialog.openFacebookMessenger(<%=PostId%>);*/

console.log(feed);

           switch (feed.feedTypeId) {
                case 4:
                    window.shareDialog.openTwitter(this.shareItem.id);
                    break;

                case 5:
                    window.shareDialog.openFacebook(this.shareItem.id);
                    break;

                case 6:
                    window.shareDialog.openLinkedIn(this.shareItem.id);
                    break;

                case 11:
                	window.shareDialog.openEmail(this.shareItem.id);
                	break;

                case 12:
                	window.shareDialog.openWhatsApp(this.shareItem.id);
                	break;

                case 13:
                	window.shareDialog.openFacebookMessenger(this.shareItem.id);
                	break;

            }


  	//let iframeUrl = process.env.REACT_APP_BASE_URL+'repost?feedItemId='+this.shareItem.id+'&feedTypeId='+feed.feedTypeId+'&activityTypeId='+feed.actionType+'&bearer='+this.props.dataLoad.apiBearer;
  	//window.wall = { currentModal: { repostCallback: this.repostCallback }};
    //this.setState({selectedItem: null, overlayContent : this.renderIframe(iframeUrl)});
  }

  renderIframe = (url) => {
  	return <iframe title="share" src={url} />
  }

  renderDate = (item) => {


    let text = TextFormatter.formatDate(item, false, this.props.dataLoad.languageManager, false);

     return ( <div className="time">
          {text}
      </div>);
  }

  renderShareFooter = (feed) => {

      var validateButton = "";
      let feedClass = feed.fontAwesome;

      let linked = this.props.dataLoad.isFeedLinked(feed);

      console.log(linked+"?");

      if(linked === false) {
        
        validateButton = <button className="btn btn-success">{this.props.dataLoad.languageManager.get(47)} <i className={feedClass} /></button>;
      } else {
        var title = this.props.dataLoad.languageManager.get(46);
        if(feed.actionType === 2) {
          title = this.props.dataLoad.languageManager.get(45);
        }
        if(feed.actionType === 4) {
          title = this.props.dataLoad.languageManager.get(292);
        }
        validateButton = <button className="btn btn-success" onClick={this.doShare.bind(this, feed)}>{title} <i className={feedClass} /></button>

        window.wall = { currentModal: { repostCallback: this.repostCallback }};
      }
      return (
          <div className="network-footer"><button className="btn btn-danger" onClick={this.props.closeItemOverlay}>{this.props.dataLoad.languageManager.get(93)}</button>{validateButton}</div>
        );
  }

  repostCallback = (data) => {
   this.showShareThanks(data, this.shareItem, this.shareFeed);
  }

  showShareThanks = (data, item, feed) => {
     this.setState({overlayContent: this.renderShareThanksContent(data, item, feed)});
  }

  renderShareThanksContent = (data, item, feed) => {
      return <ShareThanksContent dataLoad={this.props.dataLoad} feed={feed} data={data} item={item} presentLogin={this.presentLogin} onClose={this.hideOverlay} showPointsAction={false}/>
  }


  hideOverlay = () => {
      this.setState({overlayContent: null});
  }




  inviteAFriend = () => {
  	this.setState({selectedItem: this.inviteAFriendItem});
  }




	render() {
		if(this.state.isLoaded) {

		return (
				<div className="content my-profile">

					<div className="row no-gutters">
						<Overview dataLoad={this.props.dataLoad} groups={this.userGroupData} leaderboard={this.state.leaderboardData} gameLevel={this.userGameLevelData} inviteAFriend={this.inviteAFriend} inviteAFriendItem={this.inviteAFriendItem} />
						{this.renderItemsTabList()}
						{this.renderBadgesCoverflow()}
						{this.renderLeaderboardCoverflow()}
						{this.renderChannelsCoverflow()}
						{this.renderCampaignsCoverflow()}
						{this.renderRewardsCoverflow()}				
					</div>
					<Overlay content={this.state.overlayContent} exit={this.closeOverlay} rounded={true}/>
					{this.renderItemOverlay()}
				</div>
		);
		}
		return null;
	}

}

export default MyProfile;

