import React, { Component } from 'react';

class NetworkFiltersToggle extends Component {


	constructor(props) {
		super(props);

		this.state = {
			allSelected: true
		}
	}


	toggle = () => {
		if(!this.props.dataLoad.filtersManager.allNetworksSelected()) {
			//Select all networks
			this.props.dataLoad.filtersManager.setNetworksData(this.props.dataLoad.filtersManager.networksData);
			this.setState({allSelected: true});

			this.props.selectAllNetworks();
		} else {

		}

	}


	render() {

        var networkClass = "network-toggle";
        var image = this.state.allSelected ? 'fa fa-check-square' : 'fa fa-square';
        var text = this.props.dataLoad.languageManager.get(8);

		return (
			<span className={networkClass} onClick={this.toggle}><i className={image}></i>{text}</span>
		)
	}
}



export default NetworkFiltersToggle;