import React, {Component} from 'react';
import SummaryItem from './summary-item';


const SummaryCategories = [
	{valueKey: "followerCounter", textId: 116},
	{valueKey: "directActivityCount", textId: 117},
	{valueKey: "indirectActivityCount", textId: 118},
	{valueKey: "gamePoints", textId: 119},
	{valueKey: "badgeCount", textId: 120},
	{valueKey: "campaignCount", textId: 121},
]

class Summary extends Component {


	render() {
		return (
			<div className="summary row">
				{
					SummaryCategories.map((summaryData, index) => {
						return (
							<SummaryItem key={index} title={this.props.localize.get(summaryData.textId)} value={this.props.data[summaryData.valueKey]} />
						)
					})
				}
			</div>
		);


	}
}


export default Summary;