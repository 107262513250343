import React, { Component } from 'react';
import "./ephemeral-message.css";


const defaultTimeout = 3000;

class EphemeralMessage extends Component {

	constructor(props) {
		super(props);
		this.state = {
			active: false,
			timeout: this.props.timeout ? this.props.timeout : defaultTimeout
		}

	}

	show = () => {
		this.setState({active: true});
		setTimeout(() => {
			this.setState({active: false});
		}, this.state.timeout);
	}

	getClass = () => {
		let baseClass = "ephemeral-message";
		if(this.state.active) {
			return baseClass + " active";
		}
		return baseClass;
	}


  render() {

  	return(
  		<div className={this.getClass()}>
  			<div className="content">
  				<div className="message">
  					{this.props.message}
  				</div>
  			</div>
  		</div>
  	);
  }
}

export default EphemeralMessage;
