import React, { Component } from 'react';
import ComponentLoader from './component-loader';
import './coverflow.css';

class Coverflow extends Component {

  constructor(props) {
    super(props);


    this.state =  {
      items: this.props.items ? this.props.items : [],
      index: this.props.index,
      pageSize: this.props.pageSize,
      loaded: this.props.items !== undefined,
      error: null,
    }

    this.scrollRef = React.createRef();
    this.resizeListener = null;

    this.currentScroll = 0;
    this.currentAnimationFrame = null;

    this._isMounted = false;

  }

  componentDidMount = () => {
    this._isMounted = true;
      if(this.props.itemFetcher !== undefined) {
          this.loadData();//setTimeout(this.loadData, 5000);
      } else {

        if(this.state.index > 0) {
            this.refreshScroll();
        }

      }

      window.addEventListener("resize", this.refreshScroll);

  }


  componentWillUnmount = () => {
      window.removeEventListener("resize", this.refreshScroll);
      this._isMounted = false;
  }

  getItemCountPerPage = () => {
      var itemsPerPage = 3;
      if(window.innerWidth >= 1024) {
          itemsPerPage = 5;
      }
      return itemsPerPage;
  }

  getRealItemIndex = (scrollIndex) => {
    var realItemIndex = scrollIndex - this.getShift();
    if(realItemIndex < 0) {
      realItemIndex = 0;
    }
    return realItemIndex;
  }

  getRealScrollIndex = (index) => {
      if(index >= this.getShift()) {

      }
  }


  loadData = () => {
    this.props.itemFetcher.fetchItems().then((items) => {
        var selectedIndex = this.state.index;
        if(this.props.itemSelector !== undefined) {
            selectedIndex = this.props.itemSelector.getSelectedItemIndex(items);
        }
        if(this._isMounted) {
          this.setState({items: items, loaded: true, index: selectedIndex, error:null}, this.refreshScroll);
        }
    }).catch(error => {
        if(this._isMounted) {
          this.setState({error: "An error occured", loaded:true});
        }
    });
  }


  refresh = () => {
    this.setState({loaded: false}, this.loadData);
  }

  refreshScroll = () => {
    if(this.state.loaded === true) {
      this.scrollRef.current.scroll({left: this.calculateNeededScroll(this.state.index), behavior: "instant"});
    }
  }



  renderItem = (item) => {
    var itemClass = "item";
    let odd = this.getItemCountPerPage() % 2 > 0;

    let itemIndex = this.state.index;


    if(odd && this.state.items.indexOf(item) == itemIndex) {
        //Check for featured item
        itemClass += " featured";
    }

    return (
      <div className={itemClass} key={item.id}>
      {this.props.renderCoverflowItem(item)}
      </div>
      );
  }


  renderError = () => {
    if(this.state.error !== null) {
        return (<div className="error">
        <div className="message">
        {this.state.error}
        </div>
        <button className="btn btn-light" onClick={this.refresh}><i className="fas fa-redo" /></button>
      </div>);
    }

    return null;

  }

  onScroll = (event) => {
    if(this.currentAnimationFrame) {
      window.cancelAnimationFrame(this.currentAnimationFrame);
    }
    this.currentAnimationFrame = window.requestAnimationFrame(this.handleScrollSelection.bind(this, event.currentTarget.scrollLeft, event.currentTarget.scrollWidth));
  }


  handleScrollSelection = (scrollLeft, scrollWidth) => {
    let itemWidth = this.getItemWidth(scrollWidth);

    let shiftWidth = this.getShift() * itemWidth;
    let newIndex = this.getRealItemIndex(Math.round((scrollLeft+shiftWidth) / itemWidth));
    if(newIndex !== this.state.index) {
       this.setState({index: newIndex});
    }    
  }

  getItemWidth = (scrollWidth) =>  {
      return scrollWidth / (this.state.items.length+(this.getShift()*2));
  }

  getShift = () => {
      return Math.floor(this.getItemCountPerPage() / 2);
  }


  calculateNeededScroll = (index) => {
      let shift = this.getShift();
      let itemWidth = this.getItemWidth(this.scrollRef.current.scrollWidth);

      let scrollPosition = Math.round(index * itemWidth);

      return scrollPosition;
  }


  nextClick = () => {
    if(this.props.async === true) {
        this.props.nextPage().then((items) => {
            this.setState({items: items, index: 0});
        });
    } else {
        let shift = this.getShift();
        if(this.state.index < this.state.items.length-1) {
            this.setState({index: ++this.state.index}, () => {
                this.scrollRef.current.scrollLeft = this.calculateNeededScroll(this.state.index);
            });
        } else {  
            //DO nothing
        }
    }

  }

  prevClick = () => {
    if(this.props.async === true) {
        this.props.prevPage().then((items) => {
            this.setState({items: items, index: 0});
        });
    } else {
        let shift = this.getShift();

        if(this.state.index >= 0) {
            this.setState({index: --this.state.index},  () => {
                this.scrollRef.current.scrollLeft = this.calculateNeededScroll(this.state.index);
            });
        } else {
          //Do nothing
        }
    }
  }


  renderPrevArrow = ()  => {
    if(this.state.index > 0) {
        return (
            <i className="fas fa-chevron-left" onClick={this.prevClick} />
          );
    }
    return null;
  }

  renderNextArrow = () => {
       if(this.state.index < this.state.items.length-1) {
        return (

            <i className="fas fa-chevron-right" onClick={this.nextClick} />

          );
    }
    return null; 
  }


  renderItems = () => {

    let displayItems = this.state.items;/*this.state.items.slice(this.state.index, this.state.index+this.state.pageSize);*/

    return displayItems.map((item) => {
        return this.renderItem(item);
    })
  }



  renderLoader = () => {
    if(!this.state.loaded) {
      return <ComponentLoader />;
    } else {
      return null;
    }
  }


  renderContent = () => {
      return this.renderItems();
  }


  renderBlankItem = (id) => {
      if(this.state.loaded === false || this.state.error !== null) {
        return null;
      }
      let blankItemCount = Math.floor(this.getItemCountPerPage() / 2);
      var blankItems = [];
      for(var i = 0; i < blankItemCount; i++) {
          blankItems.push(<div className="item blank" key={"blankItem"+id+i} ></div>);
      }

      return blankItems;
  }





  render() {

    let coverflowClass= "coverflow coverflow-"+this.props.name;


   // let content = this.state.loaded ? this.renderContent() : this.renderLoader();

      return(
        <div className={coverflowClass}>
          <div className="wrapper">
          <div className="previous">
            {this.renderPrevArrow()}
          </div>
          <div className="content" ref={this.scrollRef} onScroll={this.onScroll}>
            { this.renderBlankItem("First") }
            {this.renderContent()}
            {this.renderError()}
            {this.renderBlankItem("Last") }
          </div>
           <div className="next">
          {this.renderNextArrow()}
          </div>
            {
              this.renderLoader()
            }
          </div>
          
        </div>
  	);
  }
}

export default Coverflow;
