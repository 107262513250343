import React, { Component } from 'react';
import "./checkbox.css";



class Checkbox extends Component {





  render() {

    var labelClass = "picker btn btn-light";
    if(this.props.selected) {
        labelClass += " active";
    }


  	return(
  		<label className={labelClass} onClick={this.props.onChange}>
        <i className="fa fa-check"></i>
  		</label>
  	);
  }
}

export default Checkbox;
