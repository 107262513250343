import React, { Component } from 'react';
import "./edit-campaign-content.css";

import CampaignItems from './campaign-items';

class EditCampaignContent extends Component {

	constructor(props) {
		super(props);


		this.state = {
			campaignsLoaded: false,
			selectedCampaign: this.props.selectedCampaign,
		}

		this.campaigns = null;

	}

	componentDidMount() {
		this.props.dataLoad.getCampaignList().then((response) => {
				this.campaigns = response;
				this.setState({campaignsLoaded: true});
		});
		
	}


	stopPropagation = (event) => {
		event.stopPropagation();
	}


	formatCampaignDate = (start, end) => {
		let from = this.props.dataLoad.languageManager.get(99);
		let to = this.props.dataLoad.languageManager.get(100);

		return from + ' ' + start + ' ' + to + ' ' + end;
	}


	campaignList = () => {
		if(this.state.campaignsLoaded) {
			return this.campaigns.map((campaign, index) => {

				return (<CampaignItems key={index} data={campaign} selected={this.isCampaignSelected(campaign.id)} onSelect={this.onSelectCampaign} formatCampaignDate={this.formatCampaignDate} />);
			})
		}

		return "";
	}

	cancel  = (event) => {
		this.props.cancel();
	}

	onSelectCampaign = (campaign) => {
		this.setState({selectedCampaign: campaign});
	}

	isCampaignSelected = (campaignId) => {
		return this.state.selectedCampaign !== undefined && campaignId === this.state.selectedCampaign.id;
	}



	next = () => {
		this.props.showCampaignItems(this.state.selectedCampaign);
	}


	renderCreateButton = () => {
		return (<div className="create"><button className="btn btn-secondary" onClick={this.createCampaign}>{this.props.dataLoad.languageManager.get(22)} <i className="fa fa-plus" /></button></div>);
	}

	createCampaign = () => {
		window.open('/admin/campaign.aspx?id=-1');
	}


	render() {
		return <div className="edit-campaign" onClick={this.stopPropagation}>

					<div className="header"><div className="title">{this.props.dataLoad.languageManager.get(52)}</div>{ this.renderCreateButton() }</div>
					<hr />
					<div className="disclaimer">
						<div className="image"><img alt="campaign" src="/app/images/campaign-image-default.png" /></div>
						<div className="content"><div className="title">{this.props.dataLoad.languageManager.get(171)}</div><div className="description">{this.props.dataLoad.languageManager.get(172)}</div></div>
					</div>
					<div className="body">
						<div className="campaigns"> 
							{this.campaignList()}
						</div>
						<div className="controls">
							<button className="btn btn-danger cancel" onClick={this.cancel}>{this.props.dataLoad.languageManager.get(93)}</button><button className="btn btn-success next" onClick={this.next} disabled={this.state.selectedCampaign === undefined}>{this.props.dataLoad.languageManager.get(91)}</button>
						</div>
					</div>
		</div>;
	}


}



export default EditCampaignContent;